import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import CloseSection from "../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import ShortChoiceLayout from "../../components/atoms/layout/shortChoiceLayout";
import Form7Choice from "../../components/molecules/choiceShort/form7Choice";

function Flow10(props) {
  const [select, setSelect] = useState([]);
  const [notApply, setNotApply] = useState(false);
  const navigate = useNavigate();

  function selectPush(id) {
    let copy = [...select];
    if (id === 8) {
      setNotApply(true);
      setSelect([8]);
      localStorage.setItem("10", JSON.stringify([8]));
      return;
    }
    if (copy.includes(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      if (copy.includes(8)) {
        copy = [];
        setNotApply(false);
      }
      if (copy.length >= 2) {
        return;
      }
      copy.push(id);
    }
    setSelect(copy);
    localStorage.setItem("10", JSON.stringify(copy));
    setNotApply(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("9")) {
      navigate("/ai-curation/flow/9");
      return;
    }
    if (localStorage.getItem("10")) {
      setSelect(JSON.parse(localStorage.getItem("10")));
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={19} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain style={{ textAlign: "center" }}>
              건강기능 식품 선택 시 가장 중요하게
              <br />
              여기는 기준이 무엇인가요? (2개선택)
            </BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <ShortChoiceLayout>
          <div
            style={{
              minHeight: "264px",
              display: "flex",
              flexWrap: "wrap",
              gap: "8px 13px",
            }}
          >
            <Form7Choice text={"프리미엄/ 유기농"} id={1} select={select} onClick={() => selectPush(1)} />
            <Form7Choice text={"맛"} id={2} select={select} onClick={() => selectPush(2)} />
            <Form7Choice text={"가성비"} id={3} select={select} onClick={() => selectPush(3)} />
            <Form7Choice text={"시럽"} id={4} select={select} onClick={() => selectPush(4)} />
            <Form7Choice text={"츄어블"} id={5} select={select} onClick={() => selectPush(5)} />
            <Form7Choice text={"섭취 편의성"} id={6} select={select} onClick={() => selectPush(6)} />
            <Form7Choice text={"휴대편의성"} id={7} select={select} onClick={() => selectPush(7)} />
            <Form7Choice text={"상관없음"} id={8} select={select} notApply={notApply} onClick={() => selectPush(8)} />
          </div>
        </ShortChoiceLayout>
        <StepBtn nextStep={"/ai-curation/flow/11"} toastOpen={select.length === 0 ? true : false} />
      </MainLayout>
    </>
  );
}

export default Flow10;
