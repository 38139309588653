import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/atoms/layout/mainLayout";
import CloseSection from "../../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../../components/atoms/text/bubbleTextMain";
import TextChoiceLayout from "../../../components/atoms/layout/textChoiceLayout";
import SpeechBubble from "../../../components/atoms/speechBubble/speechBubble";
import BubbleTextSub from "../../../components/atoms/text/bubbleTextSub";
import MultipleChoice from "../../../components/molecules/multipleChoice/mulitpleChoice";

function Flow7_1(props) {
  const navigate = useNavigate();
  const [select, setSelect] = useState([]);
  const [notApply, setNotApply] = useState(false);
  const [pageListLen, setPageListLen] = useState(null);
  const [pageIndex, setPageIndex] = useState(null);

  function selectPush(id) {
    let copy = [...select];
    if (id === 4) {
      setNotApply(true);
      setSelect([null]);
      localStorage.setItem("7-1", JSON.stringify([4]));
      return;
    }
    if (copy.includes(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      if (copy.includes(4)) {
        copy = [];
        setNotApply(false);
      }
      copy.push(id);
    }
    setSelect(copy);
    localStorage.setItem("7-1", JSON.stringify(copy));
    setNotApply(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("7")) {
      navigate("/ai-curation/flow/7");
      return;
    }
    if (localStorage.getItem("7-1")) {
      setSelect(JSON.parse(localStorage.getItem("7-1")));
    }
    setPageListLen(JSON.parse(localStorage.getItem("form7List")).length);
    setPageIndex(JSON.parse(localStorage.getItem("form7List")).indexOf("7-1"));
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={13} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>성장과 관련하여 어떤 고민이 있나요?</BubbleTextMain>
            <BubbleTextSub>중복 선택이 가능해요</BubbleTextSub>
          </SpeechBubble>
        </SpeechBubbleSet>
        <TextChoiceLayout>
          <MultipleChoice
            text={"키가 잘 자랐으면 좋겠어요"}
            id={1}
            select={select}
            onClick={() => {
              selectPush(1);
            }}
          />
          <MultipleChoice
            text={"근육 생성과 운동에 관심 있어요."}
            id={2}
            select={select}
            onClick={() => {
              selectPush(2);
            }}
          />
          <MultipleChoice
            text={"체지방이 늘었어요."}
            id={3}
            select={select}
            onClick={() => {
              selectPush(3);
            }}
          />
          <MultipleChoice
            text={"해당사항 없음"}
            id={4}
            select={select}
            notApply={notApply}
            onClick={() => {
              selectPush(4);
            }}
          />
        </TextChoiceLayout>
        <StepBtn
          nextStep={`/ai-curation/flow/${
            pageIndex < pageListLen - 1
              ? JSON.parse(localStorage.getItem("form7List"))[pageIndex + 1]
              : 8
          }`}
          toastOpen={select.length === 0 ? true : false}
        />
      </MainLayout>
    </>
  );
}

export default Flow7_1;
