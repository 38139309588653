import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainChoice from "../../../components/atoms/choice/mainChoice";
import ChoiceText20 from "../../../components/atoms/text/choiceText20";
import ChoiceLayout from "../../../components/atoms/layout/choiceLayout";
import ChoiceIco from "../../../components/atoms/choice/choiceIco";

function OxChoice({ isChecked, setIsChecked, step }) {
  useEffect(() => {
    if (localStorage.getItem(step) === "1") {
      setIsChecked(1);
      return;
    }
    if (localStorage.getItem(step) === "2") {
      setIsChecked(2);
      return;
    }
  }, []);

  return (
    <>
      <ChoiceLayout style={{ minHeight: "341px", height: "auto" }}>
        <MainChoice
          onClick={() => {
            setIsChecked(1);
            localStorage.setItem(step, 1);
          }}
          style={{
            borderColor: `${isChecked === 1 ? "#FD5592" : ""}`,
            width: "100%",
          }}
        >
          {isChecked === 1 ? (
            <img src="/imgs/ico/x-ac.svg" />
          ) : (
            <img src="/imgs/ico/x.svg" />
          )}
          <ChoiceText20
            style={{ color: `${isChecked === 1 ? "#262C31" : ""}` }}
          >
            아니오
          </ChoiceText20>
          {isChecked === 1 ? <ChoiceIco /> : ""}
        </MainChoice>
        <MainChoice
          onClick={() => {
            setIsChecked(2);
            localStorage.setItem(step, 2);
          }}
          style={{
            borderColor: `${isChecked === 2 ? "#FD5592" : ""}`,
            width: "100%",
          }}
        >
          {isChecked === 2 ? (
            <img src="/imgs/ico/o-ac.svg" />
          ) : (
            <img src="/imgs/ico/o.svg" />
          )}
          <ChoiceText20
            style={{ color: `${isChecked === 2 ? "#262C31" : ""}` }}
          >
            네
          </ChoiceText20>
          {isChecked === 2 ? <ChoiceIco /> : ""}
        </MainChoice>
      </ChoiceLayout>
    </>
  );
}

export default OxChoice;
