import api from "./api.js";

export default class CurationApi {
  static async GetCount() {
    return await api.get(`count/`);
  }
  static async GetBaseInfo() {
    return await api.get(`base_info/`);
  }
  static async GetResult(id) {
    return await api.get(`curate/${id}`);
  }
  static async Post(data) {
    return await api.post(`curate/`, data);
  }
}
