import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/atoms/layout/mainLayout";
import CloseSection from "../../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../../components/atoms/speechBubble/speechBubble";
import BubbleTextMain from "../../../components/atoms/text/bubbleTextMain";
import OxChoice from "../../../components/molecules/oxChoice/oxChoice";

function Flow7_5_2(props) {
  const [isChecked, setIsChecked] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("7-5-1")) {
      navigate("/ai-curation/flow/7-5-1");
      return;
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={13} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>식욕이 저하되거나 입맛이 없나요?</BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <OxChoice isChecked={isChecked} setIsChecked={setIsChecked} step={"7-5-2"} />
        <StepBtn nextStep={"/ai-curation/flow/7-5-3"} toastOpen={!isChecked} />
      </MainLayout>
    </>
  );
}

export default Flow7_5_2;
