import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import ShortChoiceLayout from "../../components/atoms/layout/shortChoiceLayout";
import Form7Choice from "../../components/molecules/choiceShort/form7Choice";

function Flow7(props) {
  const navigate = useNavigate();
  const [select, setSelect] = useState([]); //
  const [selectPage, setSelectPage] = useState([]);
  const [notApply, setNotApply] = useState(false);

  function selectPush(id, page) {
    const copy = [...select];
    const pageArr = [...selectPage];
    if (copy.includes(id) && pageArr.includes(page)) {
      copy.splice(copy.indexOf(id), 1);
      pageArr.splice(pageArr.indexOf(page), 1);
    } else {
      if (copy.length >= 3 && pageArr.length >= 3) {
        return;
      } else {
        copy.push(id);
        pageArr.push(page);
      }
    }
    setSelect(copy);
    setSelectPage(pageArr);
    localStorage.setItem("7", JSON.stringify(copy));
    localStorage.setItem("form7List", JSON.stringify(pageArr));
    setNotApply(false);
  }

  useEffect(() => {
    if (!(localStorage.getItem("6-1-1-5") || localStorage.getItem("6-2-5") || localStorage.getItem("6-3-1"))) {
      if (!localStorage.getItem("6-1-1-5")) {
        navigate("/ai-curation/flow/6-1-1-5");
        return;
      }
      if (!localStorage.getItem("6-2-5")) {
        navigate("/ai-curation/flow/6-2-5");
        return;
      }
      if (!localStorage.getItem("6-3-1")) {
        navigate("/ai-curation/flow/6-3-1");
        return;
      }
    }
    if (localStorage.getItem("7")) {
      setSelect(JSON.parse(localStorage.getItem("7")));
      setSelectPage(JSON.parse(localStorage.getItem("form7List")));
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={12} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain style={{ textAlign: "center" }}>
              {localStorage.getItem("name")}님에게 중요하다고 생각되는
              <br />
              3가지를 선택해주세요.
            </BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <ShortChoiceLayout>
          <Form7Choice
            id={1}
            select={select}
            text={"성장"}
            img={["/imgs/form7/growth.svg", "/imgs/form7/growth_ac.svg"]}
            onClick={() => selectPush(1, "7-1")}
          />
          <Form7Choice
            id={2}
            select={select}
            text={"면역"}
            img={["/imgs/form7/immune.svg", "/imgs/form7/immune_ac.svg"]}
            onClick={() => selectPush(2, "7-2")}
          />
          <Form7Choice
            id={3}
            select={select}
            text={"장, 변비"}
            img={["/imgs/form7/camouflage.svg", "/imgs/form7/camouflage_ac.svg"]}
            onClick={() => selectPush(3, "7-3")}
          />
          <Form7Choice
            id={4}
            select={select}
            text={"두뇌활동"}
            img={["/imgs/form7/brains.svg", "/imgs/form7/brains_ac.svg"]}
            onClick={() => selectPush(4, "7-4")}
          />
          <Form7Choice
            id={5}
            select={select}
            text={"에너지, 활동"}
            img={["/imgs/form7/energy.svg", "/imgs/form7/energy_ac.svg"]}
            onClick={() => selectPush(5, "7-5")}
          />
          <Form7Choice
            id={6}
            select={select}
            text={"수면"}
            img={["/imgs/form7/sleep.svg", "/imgs/form7/sleep_ac.svg"]}
            onClick={() => selectPush(6, "7-6")}
          />
          <Form7Choice
            id={7}
            select={select}
            text={"빈혈"}
            img={["/imgs/form7/blood.svg", "/imgs/form7/blood_ac.svg"]}
            onClick={() => selectPush(7, "7-7")}
          />
          <Form7Choice
            id={8}
            select={select}
            text={
              <span style={{ lineHeight: "19.5px" }}>
                아토피,
                <br />
                비염, 천식
              </span>
            }
            img={["/imgs/form7/nose.svg", "/imgs/form7/nose_ac.svg"]}
            onClick={() => selectPush(8, "7-8")}
          />
          <Form7Choice
            id={9}
            select={select}
            text={"뼈건강, 눈"}
            img={["/imgs/form7/bone.svg", "/imgs/form7/bone_ac.svg"]}
            onClick={() => selectPush(9, "7-9")}
          />
        </ShortChoiceLayout>
        <StepBtn
          nextStep={`/ai-curation/flow/${
            localStorage.getItem("form7List") ? JSON.parse(localStorage.getItem("form7List"))[0] : ""
          }`}
          toastOpen={select.length < 3 ? true : false}
        />
      </MainLayout>
    </>
  );
}

export default Flow7;
