import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainText from "../../atoms/text/mainText";

function BirthSelectBox({ type, setBirth, value }) {
  const [isOpen, setIsOpen] = useState(false);

  //날짜 배열 구하기
  const [yearList, setYearList] = useState([]);
  const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const dayList = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  useEffect(() => {
    const yearListArr = [];
    const now = new Date();
    const yearList = now.getFullYear();
    for (let i = 0; i <= 19; i++) {
      yearListArr.push(yearList - i);
    }
    setYearList(yearListArr);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <SelectBoxWrapper>
        <SelectBoxInput
          onClick={() => {
            if (isOpen) {
              setIsOpen(false);
            } else {
              setIsOpen(true);
            }
          }}
          style={{ borderColor: `${isOpen ? "#FD5592" : ""}` }}
        >
          <MainText
            style={{
              fontSize: "15px",
              fontWeight: "500",
              lineHeight: "22.3px",
              color: `${value || isOpen ? "#262C31" : "#D1D5D9"}`,
            }}
          >
            {value ||
              `출생${type === "year" ? "년도를" : type === "month" ? "월을" : type === "day" ? "일을" : ""} 선택하세요`}
          </MainText>
          {/* <img
            style={{ cursor: "pointer" }}
            src="/imgs/ico/arrow-down.svg"
            onClick={() => {
              if (isOpen) {
                setIsOpen(false);
              } else {
                setIsOpen(true);
              }
            }}
          /> */}
        </SelectBoxInput>
        <MainText
          style={{
            width: "34px",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "21.25px",
            color: "#636C73",
            textAlign: "right",
          }}
        >
          {type === "year" ? "년" : type === "month" ? "월" : type === "day" ? "일" : ""}
        </MainText>
      </SelectBoxWrapper>
      {isOpen ? (
        <SubMenuWrapper>
          {type === "year" ? (
            <>
              {yearList.map((item, index) => {
                return (
                  <SubMenuItem
                    key={index}
                    onClick={() => {
                      setBirth(item);
                      localStorage.setItem("year", item);
                      setIsOpen(false);
                    }}
                  >
                    {item}
                  </SubMenuItem>
                );
              })}
            </>
          ) : type === "month" ? (
            <>
              {monthList.map((item, index) => {
                return (
                  <SubMenuItem
                    key={index}
                    onClick={() => {
                      setBirth(item);
                      localStorage.setItem("month", item);
                      setIsOpen(false);
                    }}
                  >
                    {item}
                  </SubMenuItem>
                );
              })}
            </>
          ) : type === "day" ? (
            <>
              {dayList.map((item, index) => {
                return (
                  <SubMenuItem
                    key={index}
                    onClick={() => {
                      setBirth(item);
                      localStorage.setItem("day", item);
                      setIsOpen(false);
                    }}
                  >
                    {item}
                  </SubMenuItem>
                );
              })}
            </>
          ) : (
            ""
          )}
        </SubMenuWrapper>
      ) : (
        ""
      )}
    </div>
  );
}

export default BirthSelectBox;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 5px;
`;

const SelectBoxInput = styled.div.attrs((props) => {})`
  min-width: 287px;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #e6e8eb;
  background: #fff;
  box-shadow: 0px 4px 13px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px 16px 20px;
  cursor: pointer;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 160px;
  border-radius: 8px;
  border: 1px solid #e6e8eb;
  background: #fff;
  box-shadow: 0px 4px 13px 5px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  padding: 9px 0px;
  position: absolute;
  z-index: 10;
`;

const SubMenuItem = styled(MainText).attrs((props) => {})`
  width: 100%;
  height: 56px;
  padding: 17px 20px 16px 20px;
  color: #262c31;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.3px;
  cursor: pointer;
`;
