import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainText from "./mainText";

const BubbleTextMain = (props) => {
  return <BubbleTextMainWrapper {...props} />;
};

export default BubbleTextMain;

const BubbleTextMainWrapper = styled(MainText).attrs((props) => {})`
  color: #262c31;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
`;
