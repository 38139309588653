import React, { useState, useEffect } from "react";
import styled from "styled-components";

function SpeechBubble(props) {
  return (
    <>
      <SpeechBubbleWrapper style={{ ...props.style }}>
        <img src="/imgs/speechBubble/speech-corner.svg" />
        <SpeechBubbleBox>{props.children}</SpeechBubbleBox>
      </SpeechBubbleWrapper>
    </>
  );
}

export default SpeechBubble;

const SpeechBubbleWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    position: relative;
    z-index: 2;
  }
`;

const SpeechBubbleBox = styled.div.attrs((props) => {})`
  width: 100%;
  height: 87px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #f2f4f5;
  background-color: #fff;
  position: relative;
  top: -1px;
  z-index: 1;
`;
