import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../components/atoms/layout/mainLayout";
import ProgressBar from "../../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../../../components/atoms/text/bubbleTextMain";
import OxChoice from "../../../../components/molecules/oxChoice/oxChoice";
import SpeechBubble from "../../../../components/atoms/speechBubble/speechBubble";

function Flow6_1_1_3(props) {
  const [isChecked, setIsChecked] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("6-1-1-2")) {
      navigate("/ai-curation/flow/6-1-1-2");
      return;
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={9} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain style={{ textAlign: "center" }}>
              엄마가 매일 하루 1시간 이상 햇빛에
              <br />
              노출 되나요?
            </BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <OxChoice isChecked={isChecked} setIsChecked={setIsChecked} step={"6-1-1-3"} />
        <StepBtn
          nextStep={`${isChecked === 1 ? "/ai-curation/flow/6-1-1-4" : "/ai-curation/flow/6-1-1-5"}`}
          toastOpen={!isChecked}
        />
      </MainLayout>
    </>
  );
}

export default Flow6_1_1_3;
