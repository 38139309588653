import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainText from "./mainText";

const BubbleTextSub = (props) => {
  return <BubbleTextSubWrapper {...props} />;
};

export default BubbleTextSub;

const BubbleTextSubWrapper = styled(MainText).attrs((props) => {})`
  color: #949494;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.75px;
`;
