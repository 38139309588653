import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainText from "../../components/atoms/text/mainText";
import MainLayout from "../../components/atoms/layout/mainLayout";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import TextInput from "../../components/atoms/input/textInput";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import { useNavigate } from "react-router-dom";

function Flow1(props) {
  const navigate = useNavigate();
  const [name, setName] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("name")) {
      setName(localStorage.getItem("name"));
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar style={{ marginBottom: "20px" }} percent={1} />
        <MainImgWrapper>
          <img
            src="/imgs/beki/beki-001.png"
            style={{
              position: "relative",
              zIndex: "1",
              width: "170px",
              height: "170px",
            }}
          />
        </MainImgWrapper>
        <SpeechBubble
          style={{
            margin: "-8px 0 43.5px 0",
            zIndex: "2",
            position: "relative",
          }}
        >
          <MainText
            style={{
              fontSize: "15px",
              fontWeight: "500",
              lineHeight: "22.3px",
              color: "#262C31",
            }}
          >
            안녕하세요! 저는 아이에게 필요한 영양제를
          </MainText>
          <MainText
            style={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "25.2px",
              color: "#262C31",
            }}
          >
            <span style={{ color: "#FD5592" }}>추천해주는 베키</span>
            에요!
          </MainText>
        </SpeechBubble>
        <div style={{ height: "290px" }}>
          <MainText
            style={{
              fontSize: "15px",
              fontWeight: "600",
              lineHeight: "22.25px",
              color: "#949494",
            }}
          >
            만나게 되서 반가워요! 큐레이션에 앞서 ..
          </MainText>
          <MainText
            style={{
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "28px",
              color: "#262C31",
              marginBottom: "12px",
            }}
          >
            우리 꼬마 고객님의 이름을 알려주세요!
          </MainText>
          <TextInput
            placeholder={"꼬마 고객님의 이름을 적어주세요!"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              localStorage.setItem("name", e.target.value);
            }}
          />
        </div>

        <StepBtn nextStep={"/ai-curation/flow/2"} toastOpen={!name} />
      </MainLayout>
    </>
  );
}

export default Flow1;

const MainImgWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  justify-content: center;
`;
