import React, { useState } from "react";
import styled from "styled-components";

function ChoiceLayout(props) {
  return (
    <>
      <ChoiceLayoutWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </ChoiceLayoutWrapper>
    </>
  );
}

export default ChoiceLayout;

const ChoiceLayoutWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
`;
