import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainText from "../../components/atoms/text/mainText";
import MainLayout from "../../components/atoms/layout/mainLayout";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import BubbleTextSub from "../../components/atoms/text/bubbleTextSub";
import NumberInput from "../../components/atoms/input/numberInput";
import BirthSelectBox from "../../components/molecules/selectBox/birthSelectBox";

function Flow3(props) {
  const navigate = useNavigate();

  //값저장
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("gender")) {
      navigate("/ai-curation/flow/2");
      return;
    }
    setYear(localStorage.getItem("year"));
    setMonth(localStorage.getItem("month"));
    setDay(localStorage.getItem("day"));
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={3} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain style={{ marginBottom: "4px" }}>
              {localStorage.getItem("name")}님의 생년월일을 알려주세요.
            </BubbleTextMain>
            <BubbleTextSub>개월수에 따라 필요한 영양성분이 달라질 수 있어요</BubbleTextSub>
          </SpeechBubble>
        </SpeechBubbleSet>
        <div
          style={{
            minHeight: "341px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <BirthSelectBox type={"year"} setBirth={setYear} value={year} />
          <BirthSelectBox type={"month"} setBirth={setMonth} value={month} />
          <BirthSelectBox type={"day"} setBirth={setDay} value={day} />
        </div>
        <StepBtn nextStep={"/ai-curation/flow/4"} toastOpen={!(year && month && day)} />
      </MainLayout>
    </>
  );
}

export default Flow3;
