import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChoiceText16 from "../../atoms/text/choiceText16";

function MultipleChoice(props) {
  return (
    <>
      <MultipleChoiceWrapper
        onClick={() => {
          props.onClick();
        }}
        style={{
          borderColor: `${
            props.select.includes(props.id) || props.notApply ? "#FD5592" : ""
          }`,
        }}
      >
        <ChoiceText16
          style={{
            color: `${
              props.select.includes(props.id) || props.notApply ? "#FD5592" : ""
            }`,
          }}
        >
          {props.text}
        </ChoiceText16>
        {props.select.includes(props.id) || props.notApply ? (
          <img
            style={{ position: "absolute", top: "18px", right: "14px" }}
            src="/imgs/ico/check-btn.svg"
          />
        ) : (
          ""
        )}
      </MultipleChoiceWrapper>
    </>
  );
}

export default MultipleChoice;

const MultipleChoiceWrapper = styled.div.attrs((props) => {})`
  min-width: 335px;
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e6e8eb;
  box-shadow: 0px 4px 13px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
  cursor: pointer;
  position: relative;
  padding: 10px 20px;
`;
