import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/atoms/layout/mainLayout";
import ProgressBar from "../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../../components/atoms/text/bubbleTextMain";
import TextChoiceLayout from "../../../components/atoms/layout/textChoiceLayout";
import SpeechBubble from "../../../components/atoms/speechBubble/speechBubble";
import BubbleTextSub from "../../../components/atoms/text/bubbleTextSub";
import MultipleChoice from "../../../components/molecules/multipleChoice/mulitpleChoice";

function Flow7_8(props) {
  const [select, setSelect] = useState([]);
  const [notApply, setNotApply] = useState(false);
  const [pageListLen, setPageListLen] = useState(null);
  const [pageIndex, setPageIndex] = useState(null);
  const navigate = useNavigate();

  function selectPush(id) {
    const copy = [...select];
    if (copy.includes(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      copy.push(id);
    }
    setSelect(copy);
    localStorage.setItem("7-8", JSON.stringify(copy));
    setNotApply(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("7")) {
      navigate("/ai-curation/flow/7");
      return;
    }
    if (localStorage.getItem("7-8")) {
      setSelect(JSON.parse(localStorage.getItem("7-8")));
    }
    setPageListLen(JSON.parse(localStorage.getItem("form7List")).length);
    setPageIndex(JSON.parse(localStorage.getItem("form7List")).indexOf("7-8"));
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={13} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>성장과 관련하여 어떤 고민이 있나요?</BubbleTextMain>
            <BubbleTextSub>중복 선택이 가능해요</BubbleTextSub>
          </SpeechBubble>
        </SpeechBubbleSet>
        <TextChoiceLayout>
          <MultipleChoice
            text={"아토피"}
            id={1}
            select={select}
            onClick={() => selectPush(1)}
          />
          <MultipleChoice
            text={"비염"}
            id={2}
            select={select}
            onClick={() => selectPush(2)}
          />
          <MultipleChoice
            text={"천식"}
            id={3}
            select={select}
            onClick={() => selectPush(3)}
          />
        </TextChoiceLayout>
        <StepBtn
          nextStep={`/ai-curation/flow/${
            pageIndex < pageListLen - 1
              ? JSON.parse(localStorage.getItem("form7List"))[pageIndex + 1]
              : 8
          }`}
          toastOpen={select.length === 0 ? true : false}
        />
      </MainLayout>
    </>
  );
}

export default Flow7_8;
