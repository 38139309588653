import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainText from "./mainText";

const ChoiceText16 = (props) => {
  return <ChoiceText16Wrapper {...props} />;
};

export default ChoiceText16;

const ChoiceText16Wrapper = styled(MainText).attrs((props) => {})`
  color: #b8bfc4;
  font-size: 16px;
  font-weight: 600;
  line-height: 37.28px;
  letter-spacing: -0.16px;
`;
