import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainText from "../../components/atoms/text/mainText";
import MainLayout from "../../components/atoms/layout/mainLayout";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import BubbleTextSub from "../../components/atoms/text/bubbleTextSub";
import NumberInput from "../../components/atoms/input/numberInput";

function Flow4(props) {
  const navigate = useNavigate();
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);

  useEffect(() => {
    if (
      !(
        localStorage.getItem("year") &&
        localStorage.getItem("month") &&
        localStorage.getItem("day")
      )
    ) {
      navigate("/ai-curation/flow/3");
      return;
    }
    if (localStorage.getItem("height") && localStorage.getItem("weight")) {
      setHeight(localStorage.getItem("height"));
      setWeight(localStorage.getItem("weight"));
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={4} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain style={{ marginBottom: "4px" }}>
              최근 {localStorage.getItem("name")}님의 키와 몸무게 알려주세요.
            </BubbleTextMain>
            <BubbleTextSub>
              BMI 를 측정합니다. (만 2세부터 측정 가능해요)
            </BubbleTextSub>
          </SpeechBubble>
        </SpeechBubbleSet>
        <div
          style={{
            width: "100%",
            minHeight: "341px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "14px",
            }}
          >
            <NumberInput
              placeholder={"키를 입력해주세요"}
              onChange={(e) => {
                setHeight(e.target.value);
                localStorage.setItem("height", e.target.value);
              }}
              value={height}
            />
            <MainText
              style={{
                width: "34px",
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "21.25px",
                color: "#636C73",
                textAlign: "right",
              }}
            >
              cm
            </MainText>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "14px",
            }}
          >
            <NumberInput
              placeholder={"몸무게를 입력해주세요"}
              onChange={(e) => {
                setWeight(e.target.value);
                localStorage.setItem("weight", e.target.value);
              }}
              value={weight}
            />
            <MainText
              style={{
                width: "34px",
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "21.25px",
                color: "#636C73",
                textAlign: "right",
              }}
            >
              kg
            </MainText>
          </div>
        </div>

        <StepBtn
          nextStep={"/ai-curation/flow/5"}
          toastOpen={!(height && weight)}
        />
      </MainLayout>
    </>
  );
}

export default Flow4;
