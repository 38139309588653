import React, { useState } from "react";
import styled from "styled-components";
import MainText from "../../atoms/text/mainText";

function ResultSammary(props) {
  const [summaryOpen, setSummaryOpen] = useState(false);

  return (
    <>
      <SummaryWrapper>
        <MainText
          style={{
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "22.25px",
            color: "#636C73",
            marginBottom: "10px",
          }}
        >
          {props.badge}
        </MainText>
        <MainText
          style={{
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "28px",
            color: "#262C31",
            marginBottom: "6px",
          }}
        >
          {props.title}
        </MainText>
        {summaryOpen ? (
          <>
            <SummaryOpenText>{props.explain}</SummaryOpenText>
            {props.intake_set.map((item01, index) => {
              return (
                <>
                  {!(item01.max || item01.recommend) ? (
                    <IntakeWrapper>
                      <MainText
                        style={{
                          width: "100%",
                          fontSize: "13px",
                          fontWeight: "600",
                          lineHeight: "18.75px",
                          color: "#636C73",
                        }}
                      >
                        {item01.title}
                      </MainText>
                      <IntakeAmount>
                        <MainText
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "16.5px",
                            color: "#949494",
                          }}
                        >
                          충분섭취량
                        </MainText>
                        <MainText
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "37.28px ",
                            color: "#262C31",
                          }}
                        >
                          {item01.enough}
                        </MainText>
                      </IntakeAmount>
                      <IntakeGraph>
                        <IntakeBar />
                        <IntakeLine style={{ top: "-7px", left: "19.27%" }} />
                        <IntakeLine style={{ top: "-7px", right: "19.27%" }} />
                      </IntakeGraph>
                      <IntakeCaption>단위({item01.unit})</IntakeCaption>
                    </IntakeWrapper>
                  ) : !(item01.max || item01.enough) ? (
                    <IntakeWrapper>
                      <MainText
                        style={{
                          width: "100%",
                          fontSize: "13px",
                          fontWeight: "600",
                          lineHeight: "18.75px",
                          color: "#636C73",
                        }}
                      >
                        {item01.title}
                      </MainText>
                      <IntakeAmount>
                        <MainText
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "16.5px",
                            color: "#949494",
                          }}
                        >
                          권장섭취량
                        </MainText>
                        <MainText
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "37.28px ",
                            color: "#262C31",
                          }}
                        >
                          {item01.recommend}
                        </MainText>
                      </IntakeAmount>
                      <IntakeGraph>
                        <IntakeBar />
                        <IntakeLine style={{ top: "-7px", left: "19.27%" }} />
                        <IntakeLine style={{ top: "-7px", right: "19.27%" }} />
                      </IntakeGraph>
                      <IntakeCaption>단위({item01.unit})</IntakeCaption>
                    </IntakeWrapper>
                  ) : (
                    <IntakeWrapper>
                      <MainText
                        style={{
                          width: "100%",
                          fontSize: "13px",
                          fontWeight: "600",
                          lineHeight: "18.75px",
                          color: "#636C73",
                        }}
                      >
                        {item01.title}
                      </MainText>
                      <IntakeAmount
                        style={{
                          height: "58px",
                          marginTop: "14px",
                          justifyContent: "space-between",
                          padding: "0px calc(14.27% - 26px)",
                        }}
                      >
                        <IntakeNum style={{ left: "14.27%" }}>
                          {item01.recommend && (
                            <>
                              <MainText
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  lineHeight: "16.5px",
                                  color: "#949494",
                                }}
                              >
                                권장섭취량
                              </MainText>
                              <MainText
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "37.28px ",
                                  color: "#262C31",
                                }}
                              >
                                {item01.recommend}
                              </MainText>
                            </>
                          )}
                        </IntakeNum>
                        <IntakeNum>
                          {item01.enough && (
                            <>
                              <MainText
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  lineHeight: "16.5px",
                                  color: "#949494",
                                }}
                              >
                                충분섭취량
                              </MainText>
                              <MainText
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "37.28px ",
                                  color: "#262C31",
                                }}
                              >
                                {item01.enough}
                              </MainText>
                            </>
                          )}
                        </IntakeNum>
                        <IntakeNum>
                          {item01.max && (
                            <>
                              <MainText
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  lineHeight: "16.5px",
                                  color: "#949494",
                                }}
                              >
                                상한섭취량
                              </MainText>
                              <MainText
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "37.28px ",
                                  color: "#262C31",
                                }}
                              >
                                {item01.max}
                              </MainText>
                            </>
                          )}
                        </IntakeNum>
                      </IntakeAmount>
                      <IntakeGraph>
                        <IntakeBar style={{ width: "71.46%" }} />
                        <IntakeLine style={{ top: "-7px", left: "14.27%" }} />
                        <IntakeLine style={{ top: "-7px", right: "14.27%" }} />
                      </IntakeGraph>
                      <IntakeCaption>단위({item01.unit})</IntakeCaption>
                    </IntakeWrapper>
                  )}
                </>
              );
            })}
            {!props.food.length == 0 && (
              <HighInFoodWrapper>
                <MainText
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    lineHeight: "20.3px ",
                    color: "#262C31",
                  }}
                >
                  많이 함유된 음식
                </MainText>
                <MainText
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "18.75px ",
                    color: "#636C73",
                  }}
                >
                  {props.food}
                </MainText>
              </HighInFoodWrapper>
            )}
            {!props.merch_set.length == 0 && (
              <MainText
                style={{
                  fontSize: "15px",
                  fontWeight: "700",
                  lineHeight: "20.3px ",
                  color: "#262C31",
                  marginBottom: "10px",
                }}
              >
                믿고 섭취하는 베투키 영양제
              </MainText>
            )}
            {props.merch_set.map((item02, index) => {
              return (
                <PillWrapper key={index}>
                  <img
                    style={{
                      width: "115px",
                      height: "115px",
                      borderRadius: "8px",
                    }}
                    src={item02.file_url}
                  />
                  <div>
                    <MainText
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        lineHeight: "22.25px",
                        color: "#262C31",
                        marginBottom: "3px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {item02.name}
                    </MainText>
                    <MainText
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        lineHeight: "18.75px",
                        color: "#636C73",
                        marginBottom: "6px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {item02.explain}
                    </MainText>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <img src="/imgs/result/product.svg" />
                      <MainText>
                        <a
                          target="_blank"
                          style={{
                            fontSize: "15px",
                            fontWeight: "700",
                            lineHeight: "20.3px",
                            color: "#FD5592",
                          }}
                          href={item02.link}
                        >
                          상품보러가기
                        </a>
                      </MainText>
                    </div>
                  </div>
                </PillWrapper>
              );
            })}
          </>
        ) : (
          <SummaryText>{props.explain}</SummaryText>
        )}

        <SummaryBtn
          style={{ marginTop: `${summaryOpen ? "20px" : ""}` }}
          onClick={() => {
            if (summaryOpen) setSummaryOpen(false);
            else setSummaryOpen(true);
          }}
        >
          {summaryOpen ? "닫기" : "상세하게 보기"}
        </SummaryBtn>
      </SummaryWrapper>
    </>
  );
}

export default ResultSammary;

//##### ----- SUMMARY ----- #####//
const SummaryWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 21px 3px rgba(0, 0, 0, 0.03);
  padding: 34px 30px;
  margin-bottom: 16px;
`;

const SummaryText = styled(MainText).attrs((props) => {})`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #636c73;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.75px; /* 144.231% */
  margin-bottom: 10px;
`;

const SummaryOpenText = styled(MainText).attrs((props) => {})`
  width: 100%;
  color: #636c73;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.75px; /* 144.231% */
  margin-bottom: 23px;
`;

const SummaryBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--grey-2, #e6e8eb);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.75px; /* 144.231% */
  color: var(--grey-7, #262c31);
`;

const IntakeWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IntakeAmount = styled.div.attrs((props) => {})`
  width: 100%;
  height: 37px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const IntakeNum = styled.div.attrs((props) => {})`
  width: 52px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const IntakeGraph = styled.div.attrs((props) => {})`
  width: 100%;
  height: 5px;
  border-radius: 8px;
  background: #f2f4f5;
  display: flex;
  justify-content: center;
  position: relative;
`;

const IntakeBar = styled.div.attrs((props) => {})`
  width: 61.45%;
  height: 5px;
  background: #fd5592;
`;

const IntakeLine = styled.div.attrs((props) => {})`
  width: 0px;
  height: 7px;
  border-right: 1px dashed #949494;
  position: absolute;
`;

const IntakeCaption = styled(MainText).attrs((props) => {})`
  width: 100%;
  text-align: right;
  color: #b8bfc4;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.5px; /* 144.231% */
  margin-top: 4px;
`;

const HighInFoodWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PillWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 11px;
`;
