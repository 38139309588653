import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/atoms/layout/mainLayout";
import CloseSection from "../../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../../components/atoms/speechBubble/speechBubble";
import BubbleTextMain from "../../../components/atoms/text/bubbleTextMain";
import OxChoice from "../../../components/molecules/oxChoice/oxChoice";

function Flow7_7_1(props) {
  const [isChecked, setIsChecked] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("7-7")) {
      navigate("/ai-curation/flow/7-7");
      return;
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={13} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain style={{ textAlign: "center" }}>
              잘 먹으려 하지 않고 키나 몸무게가
              <br />잘 늘지 않나요?
            </BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <OxChoice isChecked={isChecked} setIsChecked={setIsChecked} step={"7-7-1"} />
        <StepBtn nextStep={"/ai-curation/flow/7-7-2"} toastOpen={!isChecked} />
      </MainLayout>
    </>
  );
}

export default Flow7_7_1;
