import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import MainText from "../../components/atoms/text/mainText";
import TextInput from "../../components/atoms/input/textInput";
import { toast } from "react-toastify";
import CurationApi from "../../api/curationApi";
import axios from "axios";

function FlowEmail(props) {
  // 이메일
  const reg = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [parentName, setParentName] = useState(null);
  const [resultId, setResultId] = useState(null);
  const [btnActive, setBtnActive] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("11")) {
      navigate("/ai-curation/flow/11");
      return;
    }
    if (localStorage.getItem("email") && localStorage.getItem("parentName")) {
      setEmail(localStorage.getItem("email"));
      setParentName(localStorage.getItem("parentName"));
    }
  }, []);

  //결과 전송
  const handleCuration = async () => {
    if (!reg.test(email)) {
      toast("이메일 형식이 올바르지 않습니다");
      return;
    }
    setBtnActive(true);
    let obj = {};
    for (let key of Object.keys(localStorage)) {
      let parsedData = null;
      if (localStorage.getItem(key)) {
        try {
          parsedData = JSON.parse(localStorage.getItem(key));
        } catch (error) {
          // JSON 파싱에 실패한 경우, 그대로 사용
          parsedData = localStorage.getItem(key);
        }
      }
      obj[key] = parsedData;
    }

    try {
      await CurationApi.Post(obj).then((response) => {
        setResultId(response.data.id);
        goResultPage(response.data, response.data.id);
      });
    } catch (error) {
      toast("서버에 문제가 생겼습니다. 잠시 후에 다시 시도해주세요");
    }
  };

  //이메일 보내기 부분
  const goResultPage = async (data, id) => {
    let info = (await CurationApi.GetBaseInfo()).data;
    let obj = {
      address: info.address,
      age: data.base.age,
      bmi: data.bmi.bmi,
      bmiTitle: data.bmi.bmi_status,
      businessNumber: info.business_number,
      ceo: info.ceo,
      couponCode: data.coupon_code,
      email: localStorage.getItem("email"),
      gender: data.base.sex,
      highBmi: data.bmi.high_limit,
      lowBmi: data.bmi.low_limit,
      messageNumber: info.business_report,
      middleBmi: data.bmi.middle_limit,
      name: localStorage.getItem("name"),
      tall: data.base.tall,
      tallAg: data.tall.avg,
      tallOrder: data.tall.rank,
      titleEmail: info.email,
      titleNumber: info.rep_phone,
      weight: data.base.weight,
      weightAg: data.weight.avg,
      weightOrder: data.weight.rank,
      nutrients: [],
    };
    data.curation_list.map((item, index) => {
      obj.nutrients.push({
        foodTitle: item.curation.food,
        nutrientContent: item.curation.explain,
        nutrientTitle: item.curation.title,
        tagTitle: item.curation.badge,
        intakes: [],
        merchs: [],
      });
      item.intake_set?.map((intakeItem) => {
        obj.nutrients[index].intakes.push({
          intake1: intakeItem.recommend,
          intake2: intakeItem.enough,
          intake3: intakeItem.max,
          name: intakeItem.title,
          type: `${intakeItem.recommend || intakeItem.max ? "2" : "1"}`,
          unit: intakeItem.unit,
        });
      });
      item.curation.merch_set?.map((merchItem) => {
        obj.nutrients[index].merchs.push({
          imageUrl: merchItem.file_url,
          link: merchItem.link,
          merchContent: merchItem.explain,
          merchName: merchItem.name,
        });
      });
    });
    try {
      await axios.post("https://curation.betoki.co.kr/util/email", obj);
    } catch (error) {
      toast("서버에 문제가 생겼습니다. 잠시 후에 다시 시도해주세요");
    }
    setBtnActive(false);
    navigate(`/ai-curation/result/${id}`);
  };

  return (
    <>
      <MainLayout>
        <EmailImgWrapper>
          <img src="/imgs/beki/beki-email.png" style={{ width: "212px", height: "212px" }} />
        </EmailImgWrapper>
        <div
          style={{
            marginBottom: "21px",
          }}
        >
          <MainText
            style={{
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "28px",
              color: "#262C31",
              textAlign: "center",
              marginBottom: "7px",
            }}
          >
            이메일과 이름을 입력해주세요
          </MainText>
          <MainText
            style={{
              fontSize: "15px",
              fontWeight: "500",
              lineHeight: "22.3px",
              color: "#949494",
              textAlign: "center",
            }}
          >
            이메일과 이름을 입력하여 맞춤 영양제를 추천받고
            <br />
            베투키 회원{" "}
            <span
              style={{
                color: "#FD5592",
                fontWeight: "700",
              }}
            >
              전용 레터와 할인 쿠폰
            </span>
            을 받아보세요!
          </MainText>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: "215px",
          }}
        >
          <TextInput
            placeholder={"이메일을 입력해주세요"}
            onChange={(e) => {
              setEmail(e.target.value);
              localStorage.setItem("email", e.target.value);
            }}
            value={email}
          />
          <TextInput
            placeholder={"본인 이름을 입력해주세요"}
            onChange={(e) => {
              setParentName(e.target.value);
              localStorage.setItem("parentName", e.target.value);
            }}
            value={parentName}
          />
        </div>
        <StepBtnWrapper>
          <StepBtnBox>
            <PrevStep
              onClick={() => {
                navigate(-1);
              }}
            >
              이전
            </PrevStep>
            <NextStep
              disabled={btnActive}
              onClick={() => {
                if (!(email && parentName)) {
                  toast("필수항목을 입력 또는 선택해주세요", {
                    icon: <img src="/imgs/toast/toast-ico.svg" />,
                  });
                  return;
                }
                handleCuration();
              }}
            >
              {btnActive ? "결과 분석 중" : "맞춤 영양제 추천받기"}
              <img
                src="/imgs/beki/beki-commend-btn.png"
                style={{
                  width: "65px",
                  position: "absolute",
                  bottom: "31px",
                  right: "5px",
                }}
              />
            </NextStep>
          </StepBtnBox>
        </StepBtnWrapper>
      </MainLayout>
    </>
  );
}

export default FlowEmail;

const EmailImgWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 38px;
  margin-bottom: 28px;
`;

const StepBtnWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

const StepBtnBox = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 99px;
`;

const PrevStep = styled.button.attrs((props) => {})`
  width: 33.13%;
  height: 48px;
  display: flex;
  border-radius: 8px;
  background: #e6e8eb;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
  color: #b8bfc4;
`;

const NextStep = styled.button.attrs((props) => {})`
  width: 63.28%;
  height: 48px;
  display: flex;
  border-radius: 8px;
  background: ${(props) => (props.disabled ? "#E6E8EB" : "#FD5592")};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props.disabled ? "#B8BFC4" : "#fff")};
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
  position: relative;
`;
