import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainText from "../../atoms/text/mainText";
import { useLocation, useNavigate } from "react-router-dom";

const CancelModal = (props) => {
  const location = useLocation();
  return (
    <ModalWrapper>
      <ModalBox>
        <MainText
          style={{
            color: "#000",
            fontsize: "16px",
            fontWeight: "600",
            lineHeight: "37.28px",
            letterSpacing: "-0.16px",
          }}
        >
          지금 AI 큐레이션을 종료하시겠어요?
        </MainText>
        <MainText
          style={{
            color: "#636C73",
            fontsize: "15px",
            fontWeight: "400",
            lineHeight: "21.25px",
            marginTop: "-5px",
            marginBottom: "17px",
          }}
        >
          종료 시 베투키 자사몰로 이동합니다
        </MainText>
        <div style={{ display: "flex", alignItems: "center", gap: "9px" }}>
          <a
            onClick={() => {
              props.setIsOpen(false);
              localStorage.clear();
            }}
            href="https://betoki.co.kr/shop"
          >
            <ModalBtn>종료 </ModalBtn>
          </a>
          <ModalBtn
            style={{
              color: "#fff",
              background: "#FD5592",
            }}
            onClick={() => props.setIsOpen(false)}
          >
            계속하기
          </ModalBtn>
        </div>
      </ModalBox>
    </ModalWrapper>
  );
};

export default CancelModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  position: fixed;
  left: 0px;
  top: 0px;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: block;
  background: #00000060;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
`;

const ModalBox = styled.div.attrs((props) => {})`
  border-radius: 16px;
  background: #fff;
  width: 335px;
  height: 167px;
  padding: 27px 15px 21px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalBtn = styled.button.attrs((props) => {})`
  border-radius: 8px;
  background: #e6e8eb;
  width: 148px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
  color: #b8bfc4;
`;
