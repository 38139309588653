import React, { useState } from "react";
import styled from "styled-components";

function ShortChoiceLayout(props) {
  return (
    <>
      <ShortChoiceLayoutWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </ShortChoiceLayoutWrapper>
    </>
  );
}

export default ShortChoiceLayout;

const ShortChoiceLayoutWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  min-height: 341px;
  height: auto;
  display: flex;
  align-items: flex-start;
  gap: 8px 13px;
  flex-wrap: wrap;
`;
