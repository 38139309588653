import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";
import TextChoiceLayout from "../../components/atoms/layout/textChoiceLayout";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import TextChoice from "../../components/atoms/choice/textChoice";
import ChoiceText16 from "../../components/atoms/text/choiceText16";

function Flow11(props) {
  const [select, setSelect] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("10")) {
      navigate("/ai-curation/flow/10");
      return;
    }
    if (localStorage.getItem("11")) {
      setSelect(JSON.parse(localStorage.getItem("11")));
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={19.5} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>어떻게 베투키를 알게 되셨나요 ?</BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <TextChoiceLayout>
          <TextChoice
            onClick={() => {
              setSelect([1]);
              setIsChecked([1]);
              localStorage.setItem("11", JSON.stringify([1]));
            }}
            style={{ borderColor: `${isChecked.includes(1) ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked.includes(1) ? "#FD5592" : ""}` }}>지인 추천</ChoiceText16>
            {isChecked.includes(1) ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
          <TextChoice
            onClick={() => {
              setSelect([2]);
              setIsChecked([2]);
              localStorage.setItem("11", JSON.stringify([2]));
            }}
            style={{ borderColor: `${isChecked.includes(2) ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked.includes(2) ? "#FD5592" : ""}` }}>
              SNS(페북, 인스타)
            </ChoiceText16>
            {isChecked.includes(2) ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
          <TextChoice
            onClick={() => {
              setSelect([3]);
              setIsChecked([3]);
              localStorage.setItem("11", JSON.stringify([3]));
            }}
            style={{ borderColor: `${isChecked.includes(3) ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked.includes(3) ? "#FD5592" : ""}` }}>
              블로그(네이버, 다음)
            </ChoiceText16>
            {isChecked.includes(3) ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
          <TextChoice
            onClick={() => {
              setSelect([4]);
              setIsChecked([4]);
              localStorage.setItem("11", JSON.stringify([4]));
            }}
            style={{ borderColor: `${isChecked.includes(4) ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked.includes(4) ? "#FD5592" : ""}` }}>TV, 유튜브 광고</ChoiceText16>
            {isChecked.includes(4) ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
          <TextChoice
            onClick={() => {
              setSelect([5]);
              setIsChecked([5]);
              localStorage.setItem("11", JSON.stringify([5]));
            }}
            style={{ borderColor: `${isChecked.includes(5) ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked.includes(5) ? "#FD5592" : ""}` }}>직접 검색</ChoiceText16>
            {isChecked.includes(5) ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
          <TextChoice
            onClick={() => {
              setSelect([6]);
              setIsChecked([6]);
              localStorage.setItem("11", JSON.stringify([6]));
            }}
            style={{ borderColor: `${isChecked.includes(6) ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked.includes(6) ? "#FD5592" : ""}` }}>기타</ChoiceText16>
            {isChecked.includes(6) ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
          {/* <MultipleChoice
            text={"지인 추천"}
            id={1}
            select={select}
            onClick={() => {
              selectPush(1);
            }}
          />
          <MultipleChoice
            text={"SNS (페북, 인스타)"}
            id={2}
            select={select}
            onClick={() => {
              selectPush(2);
            }}
          />
          <MultipleChoice
            text={"블로그 (네이버, 다음)"}
            id={3}
            select={select}
            onClick={() => {
              selectPush(3);
            }}
          />
          <MultipleChoice
            text={"TV, 유튜브 광고"}
            id={4}
            select={select}
            onClick={() => {
              selectPush(4);
            }}
          />
          <MultipleChoice
            text={"직접 검색"}
            id={5}
            select={select}
            onClick={() => {
              selectPush(5);
            }}
          />
          <MultipleChoice
            text={"기타"}
            id={6}
            select={select}
            onClick={() => {
              selectPush(6);
            }}
          /> */}
        </TextChoiceLayout>
        <StepBtn nextStep={"/ai-curation/flow/email"} toastOpen={select.length === 0 ? true : false} />
      </MainLayout>
    </>
  );
}

export default Flow11;
