import React, { useState } from "react";
import styled from "styled-components";

function TextChoiceShort(props) {
  return (
    <>
      <TextChoiceShortWrapper
        onClick={props.onClick}
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </TextChoiceShortWrapper>
    </>
  );
}

export default TextChoiceShort;

const TextChoiceShortWrapper = styled.div.attrs((props) => {})`
  min-width: 161px;
  width: 48%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e6e8eb;
  box-shadow: 0px 4px 13px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
  cursor: pointer;
  position: relative;
  padding: 6px 14px;
  gap: 9px;
`;
