import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import ShortChoiceLayout from "../../components/atoms/layout/shortChoiceLayout";
import Form7Choice from "../../components/molecules/choiceShort/form7Choice";
import BubbleTextSub from "../../components/atoms/text/bubbleTextSub";

function Flow9(props) {
  const [select, setSelect] = useState([]);
  const [notApply, setNotApply] = useState(false);
  const navigate = useNavigate();

  function selectPush(id) {
    let copy = [...select];
    if (id === 8) {
      setNotApply(true);
      setSelect([]);
      localStorage.setItem("9", JSON.stringify([]));
      return;
    }
    if (copy.includes(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      if (copy.length === 0) {
        copy = [];
        setNotApply(false);
      }
      copy.push(id);
    }
    setSelect(copy);
    localStorage.setItem("9", JSON.stringify(copy));
    setNotApply(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("8")) {
      navigate("/ai-curation/flow/8");
      return;
    }
    if (localStorage.getItem("9")) {
      setSelect(JSON.parse(localStorage.getItem("9")));
    }
  }, []);

  return (
    <>
      <MainLayout>
        {/* <CloseSection /> */}
        <ProgressBar percent={18} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>다음 중 알러지 있는 게 있나요?</BubbleTextMain>
            <BubbleTextSub>중복 선택이 가능해요.</BubbleTextSub>
          </SpeechBubble>
        </SpeechBubbleSet>
        <ShortChoiceLayout>
          <div
            style={{
              minHeight: "264px",
              display: "flex",
              flexWrap: "wrap",
              gap: "8px 13px",
            }}
          >
            <Form7Choice id={1} text={"우유"} select={select} onClick={() => selectPush(1)} />
            <Form7Choice id={2} text={"달걀"} select={select} onClick={() => selectPush(2)} />
            <Form7Choice id={3} text={"생선"} select={select} onClick={() => selectPush(3)} />
            <Form7Choice id={4} text={"조개"} select={select} onClick={() => selectPush(4)} />
            <Form7Choice id={5} text={"피넛"} select={select} onClick={() => selectPush(5)} />
            <Form7Choice id={6} text={"콩"} select={select} onClick={() => selectPush(6)} />
            <Form7Choice id={7} text={"밀가루"} select={select} onClick={() => selectPush(7)} />
            <Form7Choice id={8} text={"해당없음"} select={select} notApply={notApply} onClick={() => selectPush(8)} />
          </div>
        </ShortChoiceLayout>
        <StepBtn
          nextStep={"/ai-curation/flow/10"}
          onClick={() => {
            if (select.length === 0) {
              localStorage.setItem("9", JSON.stringify([]));
            }
          }}
          // toastOpen={select.length === 0 ? true : false}
        />
      </MainLayout>
    </>
  );
}

export default Flow9;
