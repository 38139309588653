import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/atoms/layout/mainLayout";
import ProgressBar from "../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../../components/atoms/text/bubbleTextMain";
import TextChoiceLayout from "../../../components/atoms/layout/textChoiceLayout";
import SpeechBubble from "../../../components/atoms/speechBubble/speechBubble";
import MultipleChoice from "../../../components/molecules/multipleChoice/mulitpleChoice";

function Flow7_4(props) {
  const [select, setSelect] = useState([]);
  const [notApply, setNotApply] = useState(false);
  const navigate = useNavigate();

  function selectPush(id) {
    let copy = [...select];
    if (id === 3) {
      setNotApply(true);
      setSelect([null]);
      localStorage.setItem("7-4", JSON.stringify([3]));
      return;
    }
    if (copy.includes(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      if (copy.includes(3)) {
        copy = [];
        setNotApply(false);
      }
      copy.push(id);
    }
    setSelect(copy);
    localStorage.setItem("7-4", JSON.stringify(copy));
    setNotApply(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("7")) {
      navigate("/ai-curation/flow/7");
      return;
    }
    if (localStorage.getItem("7-4")) {
      setSelect(JSON.parse(localStorage.getItem("7-4")));
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={13} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>관심 있는 내용에 선택해주세요.</BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <TextChoiceLayout>
          <MultipleChoice
            text={"생선을 일주일에 0~1회만 먹어요."}
            id={1}
            select={select}
            onClick={() => {
              selectPush(1);
            }}
          />
          <MultipleChoice
            text={"책이나 집중력이 필요한 활동을 많이해요."}
            id={2}
            select={select}
            onClick={() => {
              selectPush(2);
            }}
          />
          <MultipleChoice
            text={"해당하는 내용이 없어요."}
            id={3}
            select={select}
            notApply={notApply}
            onClick={() => {
              selectPush(3);
            }}
          />
        </TextChoiceLayout>
        <StepBtn
          nextStep={"/ai-curation/flow/7-4-1"}
          toastOpen={select.length === 0 ? true : false}
        />
      </MainLayout>
    </>
  );
}

export default Flow7_4;
