import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../components/atoms/layout/mainLayout";
import CloseSection from "../../../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../../../components/atoms/speechBubble/speechBubble";
import BubbleTextMain from "../../../../components/atoms/text/bubbleTextMain";
import TextChoice from "../../../../components/atoms/choice/textChoice";
import ChoiceText16 from "../../../../components/atoms/text/choiceText16";
import TextChoiceLayout from "../../../../components/atoms/layout/textChoiceLayout";

function Flow6_1_1_5(props) {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(null);

  useEffect(() => {
    if (!(localStorage.getItem("6-1-2-3") || localStorage.getItem("6-1-1-4") || localStorage.getItem("6-1-1-3"))) {
      if (!localStorage.getItem("6-1-2-3")) {
        navigate("/ai-curation/flow/6-1-2-3");
        return;
      }
      if (!localStorage.getItem("6-1-1-4")) {
        navigate("/ai-curation/flow/6-1-1-4");
        return;
      }
      if (!localStorage.getItem("6-1-1-3")) {
        navigate("/ai-curation/flow/6-1-1-3");
        return;
      }
    }

    let gender = localStorage.getItem("6-1-1-5");
    if (localStorage.getItem("6-1-1-5") === "1") {
      setIsChecked(1);
      return;
    }
    if (localStorage.getItem("6-1-1-5") === "2") {
      setIsChecked(2);
      return;
    }
    if (localStorage.getItem("6-1-1-5") === "3") {
      setIsChecked(3);
      return;
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={11} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>다음 중 해당되는 변상태를 고르세요.</BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <TextChoiceLayout>
          <TextChoice
            onClick={() => {
              setIsChecked(1);
              localStorage.setItem("6-1-1-5", 1);
            }}
            style={{ borderColor: `${isChecked === 1 ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked === 1 ? "#FD5592" : ""}` }}>무른변</ChoiceText16>
            {isChecked === 1 ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
          <TextChoice
            onClick={() => {
              setIsChecked(2);
              localStorage.setItem("6-1-1-5", 2);
            }}
            style={{ borderColor: `${isChecked === 2 ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked === 2 ? "#FD5592" : ""}` }}>변비</ChoiceText16>
            {isChecked === 2 ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
          <TextChoice
            onClick={() => {
              setIsChecked(3);
              localStorage.setItem("6-1-1-5", 3);
            }}
            style={{ borderColor: `${isChecked === 3 ? "#FD5592" : ""}` }}
          >
            <ChoiceText16 style={{ color: `${isChecked === 3 ? "#FD5592" : ""}` }}>둘 다 해당되지 않아요</ChoiceText16>
            {isChecked === 3 ? (
              <img style={{ position: "absolute", top: "18px", right: "14px" }} src="/imgs/ico/check-btn.svg" />
            ) : (
              ""
            )}
          </TextChoice>
        </TextChoiceLayout>
        <StepBtn nextStep={"/ai-curation/flow/7"} toastOpen={!isChecked} />
      </MainLayout>
    </>
  );
}

export default Flow6_1_1_5;
