import React, { useState } from "react";
import styled from "styled-components";

function SpeechBubbleSet(props) {
  return (
    <>
      <SpeechBubbleSetWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </SpeechBubbleSetWrapper>
    </>
  );
}

export default SpeechBubbleSet;

const SpeechBubbleSetWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18px;
`;
