import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function StepBtn(props) {
  const navigate = useNavigate();
  return (
    <>
      <StepBtnWrapper style={{ ...props.style }}>
        <StepBtnBox>
          <PrevStep
            onClick={() => {
              navigate(-1);
            }}
          >
            이전
          </PrevStep>
          <NextStep
            onClick={() => {
              if (props.onClick) {
                props.onClick();
              }
              if (props.toastOpen) {
                toast("필수항목을 입력 또는 선택해주세요", {
                  icon: <img src="/imgs/toast/toast-ico.svg" />,
                });
                return;
              }
              if (props.lastStepClick) props.lastStepClick();
              navigate(props.nextStep);
            }}
          >
            다음
          </NextStep>
        </StepBtnBox>
      </StepBtnWrapper>
    </>
  );
}

export default StepBtn;

const StepBtnWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

const StepBtnBox = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 99px;
`;

const PrevStep = styled.button.attrs((props) => {})`
  width: 33.13%;
  height: 48px;
  display: flex;
  border-radius: 8px;
  background: #e6e8eb;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
  color: #b8bfc4;
`;

const NextStep = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  border-radius: 8px;
  background: #fd5592;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
`;
