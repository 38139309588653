import React, { useState } from "react";
import styled from "styled-components";

function TextChoiceLayout(props) {
  return (
    <>
      <TextChoiceLayoutWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </TextChoiceLayoutWrapper>
    </>
  );
}

export default TextChoiceLayout;

const TextChoiceLayoutWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  min-height: 341px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`;
