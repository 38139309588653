import React, { useState, useEffect } from "react";
import styled from "styled-components";

function MainLayout(props) {
  return <MainWrapper {...props} />;
}

export default MainLayout;

const MainWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  max-width: 486px;
  height: auto;
  padding: 0px 20px;
`;
