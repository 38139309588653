import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/atoms/layout/mainLayout";
import CloseSection from "../../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../../components/atoms/speechBubble/speechBubble";
import BubbleTextMain from "../../../components/atoms/text/bubbleTextMain";
import OxChoice from "../../../components/molecules/oxChoice/oxChoice";

function Flow7_5_3(props) {
  const [isChecked, setIsChecked] = useState(null);
  const [pageListLen, setPageListLen] = useState(null);
  const [pageIndex, setPageIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("7-5-2")) {
      navigate("/ai-curation/flow/7-5-2");
      return;
    }
    setPageListLen(JSON.parse(localStorage.getItem("form7List")).length);
    setPageIndex(JSON.parse(localStorage.getItem("form7List")).indexOf("7-5"));
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={13} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>신체활동이 최근에 늘었나요?</BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <OxChoice isChecked={isChecked} setIsChecked={setIsChecked} step={"7-5-3"} />
        <StepBtn
          nextStep={`/ai-curation/flow/${
            pageIndex < pageListLen - 1 ? JSON.parse(localStorage.getItem("form7List"))[pageIndex + 1] : 8
          }`}
          toastOpen={!isChecked}
        />
      </MainLayout>
    </>
  );
}

export default Flow7_5_3;
