import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainText from "../../components/atoms/text/mainText";
import MainLayout from "../../components/atoms/layout/mainLayout";
import { useNavigate } from "react-router-dom";
import CurationApi from "../../api/curationApi";
import { toast } from "react-toastify";

function FlowStart(props) {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  useEffect(() => {
    getCount();
  }, []);

  const getCount = async () => {
    try {
      let response = (await CurationApi.GetCount()).data.count;
      setCount(response);
    } catch (error) {
      toast("서버에 문제가 생겼습니다. 잠시 후에 다시 시도해주세요");
    }
  };

  return (
    <>
      <MainLayout>
        <AiCurationTextWrapper>
          <MainText
            style={{
              fontSize: "15px",
              fontWeight: "600",
              lineHeight: "22.25px",
              color: "#44DBAE",
            }}
          >
            AI 추천 큐레이션
          </MainText>
        </AiCurationTextWrapper>
        <MainText
          style={{
            fontSize: "28px",
            fontWeight: "700",
            lineHeight: "39.2px",
            color: "#262C31",
            marginBottom: "9px",
          }}
        >
          우리 아이에게 딱맞는,
          <br />
          맞춤영양제 AI 큐레이션
        </MainText>
        <MainText
          style={{
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "22.3px",
            color: "#636C73",
            marginBottom: "14px",
          }}
        >
          정확히 알고 섭취시켜주는 것은 확실히 다릅니다.
          <br />
          맞춤 영양제 추천받고 섭취시켜주세요!
        </MainText>
        <MainImgWrapper>
          <img
            style={{ width: "269px", height: "269px" }}
            src="/imgs/beki/beki-main.png"
          />
        </MainImgWrapper>
        <StartBtnWrapper>
          <StartSpeechBubble>
            <MainText
              style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "18.75px",
                color: "#262C31",
              }}
            >
              🌟{2000 + count}명이 맞춤영양제를 추천받았어요!
            </MainText>
          </StartSpeechBubble>
          <StartBtn onClick={() => navigate("/ai-curation/flow/1")}>
            시작하기
          </StartBtn>
        </StartBtnWrapper>
      </MainLayout>
      <MainText
        style={{
          width: "100%",
          maxWidth: "486px",
          textAlign: "center",
          fontSize: "11px",
          fontWeight: "600",
          lineHeight: "14px",
          color: "#B8BFC4",
          marginBottom: "5px",
        }}
      >
        *질병진단/치료목적의 상담은 불가하니 전문의료 기간을 방문해주시길
        바랍니다.
      </MainText>
      <MainText
        style={{
          width: "100%",
          maxWidth: "486px",
          textAlign: "center",
          fontSize: "11px",
          fontWeight: "600",
          lineHeight: "14px",
          color: "#B8BFC4",
        }}
      >
        *시작하기 버튼을 누를 경우 개인정보 수집 및 활용에 동의한 것으로
        간주합니다.
      </MainText>
    </>
  );
}

export default FlowStart;

const AiCurationTextWrapper = styled.div.attrs((props) => {})`
  width: 126px;
  height: 30px;
  border-radius: 24px;
  background: rgba(68, 219, 174, 0.1);
  padding: 3px 13px 4px 13px;
  margin: 33px 0px 12px 0px;
`;

const MainImgWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
`;

const StartBtnWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
`;

const StartSpeechBubble = styled.div.attrs((props) => {})`
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  padding-top: 6px;
  background: url("/imgs/speechBubble/speech-bubble-start.svg") center center
    no-repeat;
`;

const StartBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fd5592;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 233%;
  letter-spacing: -0.16px;
  margin-bottom: 22px;
`;
