import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";
import OxChoice from "../../components/molecules/oxChoice/oxChoice";
import moment from "moment/moment";

function Flow5(props) {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(null);
  const [old] = useState(localStorage.getItem("old"));
  const [monthOld] = useState(Number(localStorage.getItem("monthOld")));
  const [birth, setBirth] = useState(null);
  const [month6, setMonth6] = useState(null);
  const [month12, setMonth12] = useState(null);

  useEffect(() => {
    if (!(localStorage.getItem("height") && localStorage.getItem("weight"))) {
      navigate("/ai-curation/flow/4");
      return;
    }

    //개월 수 비교
    let birth = new Date(localStorage.getItem("year"), localStorage.getItem("month") - 1, localStorage.getItem("day"));
    setBirth(moment(birth).format("YYYY-M-D"));

    let now = new Date();
    //6개월 날짜 저장
    now.setMonth(now.getMonth() - 6);
    setMonth6(birth > now);
    //12개월 날짜 저장
    now.setMonth(now.getMonth() - 6);
    setMonth12(birth >= now);
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={5} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>혹시 이른둥이로 태어났나요?</BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <OxChoice isChecked={isChecked} setIsChecked={setIsChecked} step={"5"} />
        {/* ##### 6개월 미만은 6-1, 6~12개월은 6-2, 13개월부터는 6-3 ##### */}
        <StepBtn
          nextStep={month6 ? "/ai-curation/flow/6-1" : month12 ? "/ai-curation/flow/6-2" : "/ai-curation/flow/6-3"}
          toastOpen={!isChecked}
        />
      </MainLayout>
    </>
  );
}

export default Flow5;
