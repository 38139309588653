import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ChoiceIco = (props) => {
  return (
    <img
      src="/imgs/ico/check-btn.svg"
      style={{
        position: "absolute",
        top: "14px",
        right: "14px",
        ...props.style,
      }}
    />
  );
};

export default ChoiceIco;
