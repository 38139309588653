import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainText from "./mainText";

const ChoiceText20 = (props) => {
  return <ChoiceText20Wrapper {...props} />;
};

export default ChoiceText20;

const ChoiceText20Wrapper = styled(MainText).attrs((props) => {})`
  color: #b8bfc4;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;
