import { useState } from "react";
import "./App.css";
import UiRouter from "./router/uiRouter";
import CancelModal from "./components/molecules/cancelModal/cancelModal";
import CloseSection from "./components/molecules/closeSection/closeSection";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className="App"
        style={{
          backgroundColor: "#FAFAFA",
          minHeight: "100vh",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CloseSection setIsOpen={setIsOpen} />
        <UiRouter setIsOpen={setIsOpen} />
        {isOpen ? <CancelModal setIsOpen={setIsOpen} /> : ""}
      </div>
      <ToastContainer className="toast" position="bottom-center" autoClose={2000} hideProgressBar={true} />
    </>
  );
}

export default App;
