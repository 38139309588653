import { Routes, Route } from "react-router-dom";
import React from "react";
import FlowStart from "../pages/flowStart/flowStart";
import Flow1 from "../pages/flow1/flow1";
import Flow2 from "../pages/flow2/flow2";
import Flow3 from "../pages/flow3/flow3";
import Flow4 from "../pages/flow4/flow4";
import Flow5 from "../pages/flow5/flow5";
import Flow6_1 from "../pages/flow6/flow6-1/flow6-1";
import Flow6_1_1 from "../pages/flow6/flow6-1/flow6-1-1/flow6-1-1";
import Flow6_1_2 from "../pages/flow6/flow6-1/flow6-1-2/flow6-1-2";
import Flow6_1_1_2 from "../pages/flow6/flow6-1/flow6-1-1/flow6-1-1-2";
import Flow6_1_1_3 from "../pages/flow6/flow6-1/flow6-1-1/flow6-1-1-3";
import Flow6_1_1_4 from "../pages/flow6/flow6-1/flow6-1-1/flow6-1-1-4";
import Flow6_1_2_2 from "../pages/flow6/flow6-1/flow6-1-2/flow6-1-2-2";
import Flow6_1_2_3 from "../pages/flow6/flow6-1/flow6-1-2/flow6-1-2-3";
import Flow6_1_1_5 from "../pages/flow6/flow6-1/flow6-1-1/flow6-1-1-5";
import Flow6_2 from "../pages/flow6/flow6-2/flow6-2";
import Flow6_2_1 from "../pages/flow6/flow6-2/flow6-2-1";
import Flow6_2_2 from "../pages/flow6/flow6-2/flow6-2-2";
import Flow6_2_3 from "../pages/flow6/flow6-2/flow6-2-3";
import Flow6_2_2_1 from "../pages/flow6/flow6-2/flow6-2-2-1";
import Flow6_2_2_2 from "../pages/flow6/flow6-2/flow6-2-2-2";
import Flow6_2_4 from "../pages/flow6/flow6-2/flow6-2-4";
import Flow6_2_5 from "../pages/flow6/flow6-2/flow6-2-5";
import Flow6_3 from "../pages/flow6/flow6-3/flow6-3";
import Flow7 from "../pages/flow7/flow7";
import Flow6_3_1 from "../pages/flow6/flow6-3/flow6-3-1";
import Flow7_1 from "../pages/flow7/flow7-1/flow7-1";
import Flow7_2 from "../pages/flow7/flow7-2/flow7-2";
import Flow7_2_1 from "../pages/flow7/flow7-2/flow7-2-1";
import Flow7_3 from "../pages/flow7/flow7-3/flow7-3";
import Flow7_3_1 from "../pages/flow7/flow7-3/flow7-3-1";
import Flow7_3_2 from "../pages/flow7/flow7-3/flow7-3-2";
import Flow7_4 from "../pages/flow7/flow7-4/flow7-4";
import Flow7_4_1 from "../pages/flow7/flow7-4/flow7-4-1";
import Flow7_5 from "../pages/flow7/flow7-5/flow7-5";
import Flow7_5_1 from "../pages/flow7/flow7-5/flow7-5-1";
import Flow7_6 from "../pages/flow7/flow7-6/flow7-6";
import Flow7_6_1 from "../pages/flow7/flow7-6/flow7-6-1";
import Flow7_6_2 from "../pages/flow7/flow7-6/flow7-6-2";
import Flow7_7 from "../pages/flow7/flow7-7/flow7-7";
import Flow7_7_1 from "../pages/flow7/flow7-7/flow7-7-1";
import Flow7_7_2 from "../pages/flow7/flow7-7/flow7-7-2";
import Flow7_8 from "../pages/flow7/flow7-8/flow7-8";
import Flow7_9 from "../pages/flow7/flow7-9/flow7-9";
import Flow7_9_1 from "../pages/flow7/flow7-9/flow7-9-1";
import Flow7_9_2 from "../pages/flow7/flow7-9/flow7-9-2";
import Flow7_9_3 from "../pages/flow7/flow7-9/flow7-9-3";
import Flow8 from "../pages/flow8/flow8";
import Flow9 from "../pages/flow9/flow9";
import Flow10 from "../pages/flow10/flow10";
import Flow11 from "../pages/flow11/flow11";
import FlowEmail from "../pages/flowEmail/flowEmail";
import Result from "../pages/result/result";
import Flow7_3_3 from "../pages/flow7/flow7-3/flow7-3-3";
import Flow7_5_2 from "../pages/flow7/flow7-5/flow7-5-2";
import Flow7_5_3 from "../pages/flow7/flow7-5/flow7-5-3";

const UiRouter = (props) => {
  return (
    <Routes>
      <Route path="/" element={<FlowStart />} />
      <Route path="/ai-curation/start" element={<FlowStart />} />
      <Route path="/ai-curation/flow/1" element={<Flow1 />} />
      <Route path="/ai-curation/flow/2" element={<Flow2 />} />
      <Route path="/ai-curation/flow/3" element={<Flow3 />} />
      <Route path="/ai-curation/flow/4" element={<Flow4 />} />
      <Route path="/ai-curation/flow/5" element={<Flow5 />} />
      <Route path="/ai-curation/flow/6-1" element={<Flow6_1 />} />
      <Route path="/ai-curation/flow/6-1-1" element={<Flow6_1_1 />} />
      <Route path="/ai-curation/flow/6-1-1-2" element={<Flow6_1_1_2 />} />
      <Route path="/ai-curation/flow/6-1-1-3" element={<Flow6_1_1_3 />} />
      <Route path="/ai-curation/flow/6-1-1-4" element={<Flow6_1_1_4 />} />
      <Route path="/ai-curation/flow/6-1-1-5" element={<Flow6_1_1_5 />} />
      <Route path="/ai-curation/flow/6-1-2" element={<Flow6_1_2 />} />
      <Route path="/ai-curation/flow/6-1-2-2" element={<Flow6_1_2_2 />} />
      <Route path="/ai-curation/flow/6-1-2-3" element={<Flow6_1_2_3 />} />
      <Route path="/ai-curation/flow/6-2" element={<Flow6_2 />} />
      <Route path="/ai-curation/flow/6-2-1" element={<Flow6_2_1 />} />
      <Route path="/ai-curation/flow/6-2-2" element={<Flow6_2_2 />} />
      <Route path="/ai-curation/flow/6-2-3" element={<Flow6_2_3 />} />
      <Route path="/ai-curation/flow/6-2-2-1" element={<Flow6_2_2_1 />} />
      <Route path="/ai-curation/flow/6-2-2-2" element={<Flow6_2_2_2 />} />
      <Route path="/ai-curation/flow/6-2-4" element={<Flow6_2_4 />} />
      <Route path="/ai-curation/flow/6-2-5" element={<Flow6_2_5 />} />
      <Route path="/ai-curation/flow/6-3" element={<Flow6_3 />} />
      <Route path="/ai-curation/flow/6-3-1" element={<Flow6_3_1 />} />
      <Route path="/ai-curation/flow/7" element={<Flow7 />} />
      <Route path="/ai-curation/flow/7-1" element={<Flow7_1 />} />
      <Route path="/ai-curation/flow/7-2" element={<Flow7_2 />} />
      <Route path="/ai-curation/flow/7-2-1" element={<Flow7_2_1 />} />
      <Route path="/ai-curation/flow/7-3" element={<Flow7_3 />} />
      <Route path="/ai-curation/flow/7-3-1" element={<Flow7_3_1 />} />
      <Route path="/ai-curation/flow/7-3-2" element={<Flow7_3_2 />} />
      <Route path="/ai-curation/flow/7-3-3" element={<Flow7_3_3 />} />
      <Route path="/ai-curation/flow/7-4" element={<Flow7_4 />} />
      <Route path="/ai-curation/flow/7-4-1" element={<Flow7_4_1 />} />
      <Route path="/ai-curation/flow/7-5" element={<Flow7_5 />} />
      <Route path="/ai-curation/flow/7-5-1" element={<Flow7_5_1 />} />
      <Route path="/ai-curation/flow/7-5-2" element={<Flow7_5_2 />} />
      <Route path="/ai-curation/flow/7-5-3" element={<Flow7_5_3 />} />
      <Route path="/ai-curation/flow/7-6" element={<Flow7_6 />} />
      <Route path="/ai-curation/flow/7-6-1" element={<Flow7_6_1 />} />
      <Route path="/ai-curation/flow/7-6-2" element={<Flow7_6_2 />} />
      <Route path="/ai-curation/flow/7-7" element={<Flow7_7 />} />
      <Route path="/ai-curation/flow/7-7-1" element={<Flow7_7_1 />} />
      <Route path="/ai-curation/flow/7-7-2" element={<Flow7_7_2 />} />
      <Route path="/ai-curation/flow/7-8" element={<Flow7_8 />} />
      <Route path="/ai-curation/flow/7-9" element={<Flow7_9 />} />
      <Route path="/ai-curation/flow/7-9-1" element={<Flow7_9_1 />} />
      <Route path="/ai-curation/flow/7-9-2" element={<Flow7_9_2 />} />
      <Route path="/ai-curation/flow/7-9-3" element={<Flow7_9_3 />} />
      <Route path="/ai-curation/flow/8" element={<Flow8 />} />
      <Route path="/ai-curation/flow/9" element={<Flow9 />} />
      <Route path="/ai-curation/flow/10" element={<Flow10 setIsOpen={props.setIsOpen} />} />
      <Route path="/ai-curation/flow/11" element={<Flow11 />} />
      <Route path="/ai-curation/flow/email" element={<FlowEmail />} />
      <Route path="/ai-curation/result/:id" element={<Result />} />
    </Routes>
  );
};

export default UiRouter;
