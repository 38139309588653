import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import MainText from "../../components/atoms/text/mainText";
import ResultSammary from "../../components/template/result/resultSammary";
import CurationApi from "../../api/curationApi";
import { toast } from "react-toastify";

function Result(props) {
  const location = useLocation();
  const params = useParams();
  const [result, setResult] = useState();

  //결과 받아오기
  useEffect(() => {
    getResult(params.id);
  }, []);

  const getResult = async (id) => {
    try {
      let response = (await CurationApi.GetResult(id)).data;
      setResult(response);
      console.log(response);
      localStorage.clear();
    } catch (error) {
      toast("서버에 문제가 생겼습니다. 잠시 후에 다시 시도해주세요");
    }
  };

  //공유하기 버튼
  const windowUrl = window.location.href;
  const sharePage = () => {
    const shareObject = {
      title: "베투키 AI 추천 큐레이션 결과",
      text: "베투키",
      url: windowUrl,
    };

    if (navigator.share) {
      // Navigator를 지원하는 경우만 실행
      navigator
        .share(shareObject)
        .then(() => {
          // 정상 동작할 경우 실행
          // alert("공유하기 성공");
        })
        .catch((error) => {
          return;
        });
    } else {
      // navigator를 지원하지 않는 경우
      window.navigator.clipboard
        .writeText(windowUrl)
        .then(() => {
          alert("주소가 복사되었습니다");
        })
        .catch((error) => {
          return;
        });
    }
  };

  return (
    <>
      <MainLayout>
        <ResultTopWrapper>
          <AiCurationTextWrapper>
            <MainText
              style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "18.75px",
                color: "#FFF",
              }}
            >
              AI 추천 큐레이션
            </MainText>
          </AiCurationTextWrapper>
          <MainText
            style={{
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "34.5px",
              letterSpacing: "-0.168px",
              color: "#262C31",
            }}
          >
            우리 아이에게 딱 맞는,
            <br />
            맞춤영양제!
            <br />
            추천해드릴게요
          </MainText>
          <img
            src="/imgs/beki/beki-result.png"
            style={{
              position: "absolute",
              top: "-19px",
              right: "-10px",
              width: "180px",
              height: "180px",
            }}
          />
        </ResultTopWrapper>
        <MainText
          style={{
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "21.5px",
            color: "#636C73",
            marginBottom: "24px",
          }}
        >
          분유량, 모유량, 먹고 있는 영양성분에 따라 추천해
          <br />
          드려야 하는 양이 다르기 때문에 충분·권장섭취량만
          <br />
          안내를 드립니다.
        </MainText>

        {/* ###### BMI 부분 ###### */}
        <BmiWrapper>
          <BmiBorder />
          <BmiInfoWrapper>
            <BmiBasicInfo>
              <BmiTextWrapper>
                <MainText
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "18.75px",
                    color: "#949494",
                  }}
                >
                  성별
                </MainText>
                <BmiMainText>{result?.base.sex}</BmiMainText>
              </BmiTextWrapper>
              <BmiTextWrapper>
                <MainText
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "18.75px",
                    color: "#949494",
                  }}
                >
                  나이
                </MainText>
                <BmiMainText>{result?.base.age}</BmiMainText>
              </BmiTextWrapper>
              <BmiTextWrapper>
                <MainText
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "18.75px",
                    color: "#949494",
                  }}
                >
                  키
                </MainText>
                <BmiMainText>{result?.base.tall}cm</BmiMainText>
              </BmiTextWrapper>
              <BmiTextWrapper>
                <MainText
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "18.75px",
                    color: "#949494",
                  }}
                >
                  몸무게
                </MainText>
                <BmiMainText>{result?.base.weight}kg</BmiMainText>
              </BmiTextWrapper>
            </BmiBasicInfo>
            <BmiDetailInfo>
              <div>
                <BmiMainText style={{ textAlign: "left", marginBottom: "6px" }}>키 {result?.base.tall}cm</BmiMainText>
                <MainText
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16.5px",
                    color: "#B8BFC4",
                  }}
                >
                  평균 {result?.tall.avg}cm
                </MainText>
              </div>
              <div>
                <BmiMainText
                  style={{
                    textAlign: "right",
                    color: "#636C73",
                    width: "100%",
                  }}
                >
                  또래 친구 100명 중 <span style={{ color: "#F96399" }}>{result?.tall.rank}번째</span>
                </BmiMainText>
              </div>
            </BmiDetailInfo>
            <BmiDevider />
            <BmiDetailInfo>
              <div>
                <BmiMainText style={{ textAlign: "left", marginBottom: "6px" }}>
                  몸무게 {result?.base.weight}kg
                </BmiMainText>
                <MainText
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16.5px",
                    color: "#B8BFC4",
                  }}
                >
                  평균 {result?.weight.avg}kg
                </MainText>
              </div>
              <div>
                <BmiMainText
                  style={{
                    textAlign: "right",
                    color: "#636C73",
                    width: "100%",
                  }}
                >
                  또래 친구 100명 중 <span style={{ color: "#D45CF2" }}>{result?.weight.rank}번째</span>
                </BmiMainText>
              </div>
            </BmiDetailInfo>
            {result?.bmi.use_bmi ? (
              <>
                <BmiDevider />
                <BmiDetailInfo>
                  <div>
                    <BmiMainText
                      style={{
                        textAlign: "left",
                        marginBottom: "6px",
                        width: "100%",
                      }}
                    >
                      체질량지수 BMI {result.bmi.bmi}
                    </BmiMainText>
                  </div>
                  <div>
                    <BmiMainText
                      style={{
                        textAlign: "right",
                        color: "#636C73",
                        width: "100%",
                      }}
                    >
                      <span style={{ color: "#F368CC" }}>{result.bmi.bmi_status}</span> 지수에요
                    </BmiMainText>
                  </div>
                </BmiDetailInfo>
                {/* <BmiGraphWrapper>
                  <BmiIndex>
                    <MainText
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        color: "#B8BFC4",
                      }}
                    >
                      저체중 {result.bmi.low_limit}
                    </MainText>
                    <MainText
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        color: "#B8BFC4",
                      }}
                    >
                      정상 {result.bmi.middle_limit}
                    </MainText>
                    <MainText
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        color: "#B8BFC4",
                      }}
                    >
                      과체중 {result.bmi.high_limit}
                    </MainText>
                    <MainText
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        color: "#B8BFC4",
                      }}
                    >
                      비만 {result.bmi.high_limit}~
                    </MainText>
                  </BmiIndex>
                  <BmiGraph>
                    <BmiLine style={{ top: "-7px", left: "17.27%" }} />
                    <BmiLine style={{ top: "-7px", left: "38.27%" }} />
                    <BmiLine style={{ top: "-7px", left: "61.27%" }} />
                    <BmiGraphBar style={{ width: `calc(${bmiBarWidth}%)` }} />
                  </BmiGraph>
                  <BmiNum style={{ left: `calc(${bmiBarWidth}% - 19px)` }}>
                    {result.bmi.bmi}
                  </BmiNum>
                </BmiGraphWrapper> */}
              </>
            ) : (
              ""
            )}
          </BmiInfoWrapper>
        </BmiWrapper>
        {/* ###### SUMMARY ###### */}
        {result?.analyze_list.map((item, index) => {
          return (
            <ResultSammary
              key={index}
              badge={item.curation.badge}
              title={item.curation.title}
              explain={item.curation.explain}
              intake_set={item.intake_set}
              food={item.curation.food}
              merch_set={item.curation.merch_set}
            />
          );
        })}

        {/* ###### SUGGEST ###### */}
        {result?.curation_list.map((item, index) => {
          return (
            <SuggestWrapper key={index}>
              <MainText
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "22.25px",
                  color: "#636C73",
                  marginBottom: "6px",
                }}
              >
                {item.curation.badge}
              </MainText>
              <MainText
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "28px",
                  color: "#262C31",
                  marginBottom: "6px",
                }}
              >
                {item.curation.title}
              </MainText>
              <SuggestText>{item.curation.explain}</SuggestText>
              {/* ######## 충분섭취량 하나만 있을 때 ######## */}
              {item.intake_set.map((item01, index) => {
                return (
                  <>
                    {!(item01.max || item01.recommend) ? (
                      <IntakeWrapper>
                        <MainText
                          style={{
                            width: "100%",
                            fontSize: "13px",
                            fontWeight: "600",
                            lineHeight: "18.75px",
                            color: "#636C73",
                          }}
                        >
                          {item01.title}
                        </MainText>
                        <IntakeAmount>
                          <MainText
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "16.5px",
                              color: "#949494",
                            }}
                          >
                            충분섭취량
                          </MainText>
                          <MainText
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              lineHeight: "37.28px ",
                              color: "#262C31",
                            }}
                          >
                            {item01.enough}
                          </MainText>
                        </IntakeAmount>
                        <IntakeGraph>
                          <IntakeBar />
                          <IntakeLine style={{ top: "-7px", left: "19.27%" }} />
                          <IntakeLine style={{ top: "-7px", right: "19.27%" }} />
                        </IntakeGraph>
                        <IntakeCaption>단위({item01.unit})</IntakeCaption>
                      </IntakeWrapper>
                    ) : !(item01.max || item01.enough) ? (
                      <IntakeWrapper>
                        <MainText
                          style={{
                            width: "100%",
                            fontSize: "13px",
                            fontWeight: "600",
                            lineHeight: "18.75px",
                            color: "#636C73",
                          }}
                        >
                          {item01.title}
                        </MainText>
                        <IntakeAmount>
                          <MainText
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "16.5px",
                              color: "#949494",
                            }}
                          >
                            권장섭취량
                          </MainText>
                          <MainText
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              lineHeight: "37.28px ",
                              color: "#262C31",
                            }}
                          >
                            {item01.recommend}
                          </MainText>
                        </IntakeAmount>
                        <IntakeGraph>
                          <IntakeBar />
                          <IntakeLine style={{ top: "-7px", left: "19.27%" }} />
                          <IntakeLine style={{ top: "-7px", right: "19.27%" }} />
                        </IntakeGraph>
                        <IntakeCaption>단위({item01.unit})</IntakeCaption>
                      </IntakeWrapper>
                    ) : (
                      <IntakeWrapper>
                        <MainText
                          style={{
                            width: "100%",
                            fontSize: "13px",
                            fontWeight: "600",
                            lineHeight: "18.75px",
                            color: "#636C73",
                          }}
                        >
                          {item01.title}
                        </MainText>
                        <IntakeAmount
                          style={{
                            height: "58px",
                            marginTop: "14px",
                            justifyContent: "space-between",
                            padding: "0px calc(14.27% - 26px)",
                          }}
                        >
                          <IntakeNum style={{ left: "14.27%" }}>
                            {item01.recommend && (
                              <>
                                <MainText
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "16.5px",
                                    color: "#949494",
                                  }}
                                >
                                  권장섭취량
                                </MainText>
                                <MainText
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    lineHeight: "37.28px ",
                                    color: "#262C31",
                                  }}
                                >
                                  {item01.recommend}
                                </MainText>
                              </>
                            )}
                          </IntakeNum>
                          <IntakeNum>
                            {item01.enough && (
                              <>
                                <MainText
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "16.5px",
                                    color: "#949494",
                                  }}
                                >
                                  충분섭취량
                                </MainText>
                                <MainText
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    lineHeight: "37.28px ",
                                    color: "#262C31",
                                  }}
                                >
                                  {item01.enough}
                                </MainText>
                              </>
                            )}
                          </IntakeNum>
                          <IntakeNum>
                            {item01.max && (
                              <>
                                <MainText
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "16.5px",
                                    color: "#949494",
                                  }}
                                >
                                  상한섭취량
                                </MainText>
                                <MainText
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    lineHeight: "37.28px ",
                                    color: "#262C31",
                                  }}
                                >
                                  {item01.max}
                                </MainText>
                              </>
                            )}
                          </IntakeNum>
                        </IntakeAmount>
                        <IntakeGraph>
                          <IntakeBar style={{ width: "71.46%" }} />
                          <IntakeLine style={{ top: "-7px", left: "14.27%" }} />
                          <IntakeLine style={{ top: "-7px", right: "14.27%" }} />
                        </IntakeGraph>
                        <IntakeCaption>단위({item01.unit})</IntakeCaption>
                      </IntakeWrapper>
                    )}
                  </>
                );
              })}
              {!item.curation.food.length == 0 && (
                <HighInFoodWrapper>
                  <MainText
                    style={{
                      fontSize: "15px",
                      fontWeight: "700",
                      lineHeight: "20.3px ",
                      color: "#262C31",
                    }}
                  >
                    많이 함유된 음식
                  </MainText>
                  <MainText
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "18.75px ",
                      color: "#636C73",
                    }}
                  >
                    {item.curation.food}
                  </MainText>
                </HighInFoodWrapper>
              )}
              {!item.curation.merch_set.length == 0 && (
                <MainText
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    lineHeight: "20.3px ",
                    color: "#262C31",
                    marginBottom: "10px",
                  }}
                >
                  믿고 섭취하는 베투키 영양제
                </MainText>
              )}
              {item.curation.merch_set.map((item02, index) => {
                return (
                  <PillWrapper key={index}>
                    <img
                      style={{
                        width: "115px",
                        height: "115px",
                        borderRadius: "8px",
                      }}
                      src={item02.file_url}
                    />
                    <div>
                      <MainText
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          lineHeight: "22.25px",
                          color: "#262C31",
                          marginBottom: "3px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item02.name}
                      </MainText>
                      <MainText
                        style={{
                          fontSize: "13px",
                          fontWeight: "400",
                          lineHeight: "18.75px",
                          color: "#636C73",
                          marginBottom: "6px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item02.explain}
                      </MainText>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <img src="/imgs/result/product.svg" />
                        <MainText>
                          <a
                            target="_blank"
                            style={{
                              fontSize: "15px",
                              fontWeight: "700",
                              lineHeight: "20.3px",
                              color: "#FD5592",
                            }}
                            href={item02.link}
                          >
                            상품보러가기
                          </a>
                        </MainText>
                      </div>
                    </div>
                  </PillWrapper>
                );
              })}
            </SuggestWrapper>
          );
        })}
      </MainLayout>
      <ResultBtnWrapper>
        <ResultBtnBox>
          <ShareBtn>
            <img src="/imgs/result/share-ico.svg" onClick={sharePage} />
          </ShareBtn>
          <GoMallBtn>
            <a style={{ color: "#fff" }} href="https://betoki.co.kr/shop">
              베투키 자사몰 바로가기
            </a>
          </GoMallBtn>
        </ResultBtnBox>
      </ResultBtnWrapper>
    </>
  );
}

export default Result;

const ResultTopWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  margin-top: 19px;
  margin-bottom: 27px;
  position: relative;
`;

const AiCurationTextWrapper = styled.div.attrs((props) => {})`
  width: 115px;
  height: 30px;
  border-radius: 24px;
  background: #262c31;
  padding: 5px 11px 6px 11px;
  margin: 0px 0px 9px 0px;
`;

//##### ----- BMI ----- #####//
const BmiWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
`;

const BmiBorder = styled.div.attrs((props) => {})`
  width: 100%;
  height: 4px;
  background: #262c31;
  border-radius: 5px 5px 0px 0px;
`;

const BmiInfoWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  min-height: 230px;
  height: auto;
  border-radius: 0px 0px 12px 12px;
  background: #ffffff;
  padding: 28px 0px 28px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BmiBasicInfo = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
  padding: 0 8px 0 7px;
`;

const BmiTextWrapper = styled.div.attrs((props) => {})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 80px;
`;

const BmiMainText = styled(MainText).attrs((props) => {})`
  color: #262c31;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.75px;
  width: 80px;
  text-align: center;
`;

const BmiDetailInfo = styled.div.attrs((props) => {})`
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const BmiDevider = styled.div.attrs((props) => {})`
  width: 82.09%;
  padding: 0px 30px;
  margin: 16px 0px;
  height: 0px;
  border: 1px dashed #e6e8eb;
`;

const BmiGraphWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  padding: 0px 30px;
  margin: 16px 0px 0px 0px;
  position: relative;
`;

const BmiIndex = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 4.66%;
  gap: 4.66%;
  position: relative;
  transform: translateX(4px);
`;

const BmiGraph = styled.div.attrs((props) => {})`
  width: 100%;
  height: 5px;
  border-radius: 8px;
  background: #f2f4f5;
  display: flex;
  justify-content: start;
  position: relative;
  margin-top: 14px;
`;

const BmiLine = styled.div.attrs((props) => {})`
  width: 0px;
  height: 7px;
  border-right: 1px dashed #949494;
  position: absolute;
`;

const BmiGraphBar = styled.div.attrs((props) => {})`
  height: 5px;
  background: #f368cc;
  border-radius: 8px 0px 0px 8px;
`;

const BmiNum = styled.div.attrs((props) => {})`
  width: 38px;
  height: 25px;
  border-radius: 8px;
  border: 1px solid var(--grey-2, #e6e8eb);
  background: #fff;
  box-shadow: 0px 4px 13px 5px rgba(0, 0, 0, 0.05);
  margin-top: 3px;
  color: #f368cc;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px; /* 163.333% */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

//##### ----- SUMMARY ----- #####//
const SummaryWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 21px 3px rgba(0, 0, 0, 0.03);
  padding: 34px 30px;
  margin-bottom: 16px;
`;

const SummaryText = styled(MainText).attrs((props) => {})`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #636c73;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.75px; /* 144.231% */
  margin-bottom: 10px;
`;

const SummaryOpenText = styled(MainText).attrs((props) => {})`
  width: 100%;
  color: #636c73;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.75px; /* 144.231% */
  margin-bottom: 10px;
`;

const SummaryBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--grey-2, #e6e8eb);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.75px; /* 144.231% */
  color: var(--grey-7, #262c31);
`;

//##### ----- SUGGEST ----- #####//
const SuggestWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 21px 3px rgba(0, 0, 0, 0.03);
  padding: 34px 30px;
  margin-bottom: 16px;
`;

const SuggestText = styled(MainText).attrs((props) => {})`
  width: 100%;
  color: #636c73;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.75px; /* 144.231% */
  margin-bottom: 15px;
`;

const IntakeWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IntakeAmount = styled.div.attrs((props) => {})`
  width: 100%;
  height: 37px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const IntakeNum = styled.div.attrs((props) => {})`
  width: 52px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const IntakeGraph = styled.div.attrs((props) => {})`
  width: 100%;
  height: 5px;
  border-radius: 8px;
  background: #f2f4f5;
  display: flex;
  justify-content: center;
  position: relative;
`;

const IntakeBar = styled.div.attrs((props) => {})`
  width: 61.45%;
  height: 5px;
  background: #fd5592;
`;

const IntakeLine = styled.div.attrs((props) => {})`
  width: 0px;
  height: 7px;
  border-right: 1px dashed #949494;
  position: absolute;
`;

const IntakeCaption = styled(MainText).attrs((props) => {})`
  width: 100%;
  text-align: right;
  color: #b8bfc4;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.5px; /* 144.231% */
  margin-top: 4px;
`;

const HighInFoodWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PillWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 11px;
`;

//##### ----- BOTTOM BTN ----- #####//
const ResultBtnWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 41px 24px;
  background-color: #ffffff;
  width: 100%;
  max-width: 486px;
  position: sticky;
  bottom: 0;
`;

const ResultBtnBox = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const ShareBtn = styled.button.attrs((props) => {})`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #e6e8eb;
`;

const GoMallBtn = styled.button.attrs((props) => {})`
  width: 85%;
  height: 48px;
  display: flex;
  border-radius: 8px;
  background: #fd5592;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
`;
