import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/atoms/layout/mainLayout";
import ProgressBar from "../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../../components/atoms/speechBubble/speechBubble";
import BubbleTextMain from "../../../components/atoms/text/bubbleTextMain";
import OxChoice from "../../../components/molecules/oxChoice/oxChoice";

function Flow6_1(props) {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("5")) {
      navigate("/ai-curation/flow/5");
      return;
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={6} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>모유수유 중이십니까?</BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <OxChoice isChecked={isChecked} setIsChecked={setIsChecked} step={"6-1"} />
        <StepBtn
          nextStep={`${isChecked === 1 ? "/ai-curation/flow/6-1-2" : "/ai-curation/flow/6-1-1"}`}
          toastOpen={!isChecked}
        />
      </MainLayout>
    </>
  );
}

export default Flow6_1;
