import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextChoiceShort from "../../atoms/choice/textChoiceShort";
import ChoiceIco from "../../atoms/choice/choiceIco";
import MainText from "../../atoms/text/mainText";

function Form7Choice(props) {
  return (
    <>
      <TextChoiceShort
        onClick={() => {
          props.onClick();
        }}
        style={{
          borderColor: `${
            props.select.includes(props.id) || props.notApply ? "#FD5592" : ""
          }`,
        }}
      >
        {props?.img ? (
          <Form7Img
            src={
              props.select.includes(props.id) || props.notApply
                ? props.img[1]
                : props.img[0]
            }
          />
        ) : (
          ""
        )}

        <Form7Text
          style={{
            color: `${
              props.select.includes(props.id) || props.notApply ? "#FD5592" : ""
            }`,
          }}
        >
          {props.text}
        </Form7Text>
        {props.select.includes(props.id) || props.notApply ? (
          <ChoiceIco style={{ top: "8px", right: "8px" }} />
        ) : (
          ""
        )}
      </TextChoiceShort>
    </>
  );
}

export default Form7Choice;

const Form7Img = styled.img.attrs((props) => {})`
  width: 32px;
  height: 32px;
`;

const Form7Text = styled(MainText).attrs((props) => {})`
  color: #b8bfc4;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.048px;
`;
