import React, { useState, useEffect } from "react";
import styled from "styled-components";

function TextInput({ placeholder, value, onChange, disabled, style }) {
  return (
    <>
      <div
        style={{
          width: "100%",
          boxShadow: "0px 4px 13px 5px rgba(0, 0, 0, 0.05)",
          borderRadius: "8px",
        }}
      >
        <TextInputWrapper
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={{
            ...style,
          }}
        />
      </div>
    </>
  );
}

export default TextInput;

const TextInputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 56px;
  padding: 17px 20px 16px 20px;
  border-radius: 8px;
  border: 1px solid #e6e8eb;
  background: #fff;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.3px; /* 141.667% */
  color: #262c31;

  &::placeholder {
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.3px; /* 141.667% */
    color: #d1d5d9;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--sub-color-3, #fd5592);
  }
`;
