import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../components/atoms/layout/mainLayout";
import CloseSection from "../../../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../../../components/atoms/speechBubble/speechBubble";
import BubbleTextMain from "../../../../components/atoms/text/bubbleTextMain";
import OxChoice from "../../../../components/molecules/oxChoice/oxChoice";

function Flow6_1_2_3(props) {
  const [isChecked, setIsChecked] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("6-1-2-3")) {
      navigate("/ai-curation/flow/6-1-2-3");
      return;
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={9} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>
              분유를 하루 800cc 미만으로 섭취 하나요?
            </BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <OxChoice
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          step={"6-1-2-3"}
        />
        <StepBtn
          nextStep={"/ai-curation/flow/6-1-1-5"}
          toastOpen={!isChecked}
        />
      </MainLayout>
    </>
  );
}

export default Flow6_1_2_3;
