import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";
import TextChoiceLayout from "../../components/atoms/layout/textChoiceLayout";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import MultipleChoice from "../../components/molecules/multipleChoice/mulitpleChoice";

function Flow8(props) {
  const [select, setSelect] = useState([]);
  const [notApply, setNotApply] = useState(false);
  const navigate = useNavigate();

  function selectPush(id) {
    let copy = [...select];
    if (id === 8) {
      setNotApply(true);
      setSelect([null]);
      localStorage.setItem("8", JSON.stringify([8]));
      return;
    }
    if (copy.includes(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      if (copy.includes(8)) {
        copy = [];
        setNotApply(false);
      }
      copy.push(id);
    }
    setSelect(copy);
    localStorage.setItem("8", JSON.stringify(copy));
    setNotApply(false);
  }

  useEffect(() => {
    if (
      !(
        localStorage.getItem("7-1") ||
        localStorage.getItem("7-2-1") ||
        localStorage.getItem("7-3-3") ||
        localStorage.getItem("7-4-1") ||
        localStorage.getItem("7-5-3") ||
        localStorage.getItem("7-6-2") ||
        localStorage.getItem("7-7-2") ||
        localStorage.getItem("7-8") ||
        localStorage.getItem("7-9-3")
      )
    ) {
      if (!localStorage.getItem("7-1")) {
        navigate("/ai-curation/flow/7-1");
        return;
      }
      if (!localStorage.getItem("7-2-1")) {
        navigate("/ai-curation/flow/7-2-1");
        return;
      }
      if (!localStorage.getItem("7-3-3")) {
        navigate("/ai-curation/flow/7-3-3");
        return;
      }
      if (!localStorage.getItem("7-4-1")) {
        navigate("/ai-curation/flow/7-4-1");
        return;
      }
      if (!localStorage.getItem("7-5-3")) {
        navigate("/ai-curation/flow/7-5-3");
        return;
      }
      if (!localStorage.getItem("7-6-2")) {
        navigate("/ai-curation/flow/7-6-2");
        return;
      }
      if (!localStorage.getItem("7-7-2")) {
        navigate("/ai-curation/flow/7-7-2");
        return;
      }
      if (!localStorage.getItem("7-8")) {
        navigate("/ai-curation/flow/7-8");
        return;
      }
      if (!localStorage.getItem("7-9-3")) {
        navigate("/ai-curation/flow/7-9-3");
        return;
      }
    }

    if (localStorage.getItem("8")) {
      setSelect(JSON.parse(localStorage.getItem("8")));
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={17} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain style={{ textAlign: "center" }}>
              다음 중 해당되는 식습관 상태를
              <br />
              체크해주세요.
            </BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <TextChoiceLayout>
          <MultipleChoice
            text={"초록잎 야채를 잘 먹지 않아요."}
            id={1}
            select={select}
            onClick={() => {
              selectPush(1);
            }}
          />
          <MultipleChoice
            text={"식이섬유를 잘 먹지 않아요."}
            id={2}
            select={select}
            onClick={() => {
              selectPush(2);
            }}
          />
          <MultipleChoice
            text={"고기를 주 3회 미만 먹어요."}
            id={3}
            select={select}
            onClick={() => {
              selectPush(3);
            }}
          />
          <MultipleChoice
            text={"탄수화물 단백질 섭취량이 많아요."}
            id={4}
            select={select}
            onClick={() => {
              selectPush(4);
            }}
          />
          <MultipleChoice
            text={"입이 짧아요."}
            id={5}
            select={select}
            onClick={() => {
              selectPush(5);
            }}
          />
          <MultipleChoice
            text={"생선을 잘 먹지 않아요. (주 3회미만)"}
            id={6}
            select={select}
            onClick={() => {
              selectPush(6);
            }}
          />
          <MultipleChoice
            text={"유제품을 잘 먹지 않아요. (하루 2잔 미만)"}
            id={7}
            select={select}
            onClick={() => {
              selectPush(7);
            }}
          />
          <MultipleChoice
            text={"해당사항 없음"}
            id={8}
            select={select}
            notApply={notApply}
            onClick={() => {
              selectPush(8);
            }}
          />
        </TextChoiceLayout>
        <StepBtn
          nextStep={"/ai-curation/flow/9"}
          toastOpen={select.length === 0 ? true : false}
        />
      </MainLayout>
    </>
  );
}

export default Flow8;
