import React from "react";
import styled from "styled-components";

function ProgressBar(props) {
  return (
    <>
      <ProgressBarWrapper {...props}>
        <ProgressBarInfo percent={(props.percent / 20) * 100} />
      </ProgressBarWrapper>
    </>
  );
}

export default ProgressBar;

const ProgressBarWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 5px;
  border-radius: 9px;
  background: #e6e8eb;
  margin: 5px 0 35px 0;
`;

const ProgressBarInfo = styled.div.attrs((props) => {})`
  width: ${(props) => props.percent}%;
  height: 5px;
  border-radius: 9px;
  background: #44dbae;
`;
