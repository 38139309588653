import React, { useState } from "react";
import styled from "styled-components";

function CloseSection(props) {
  return (
    <>
      <CloseSectionWrapper style={{ height: "56px" }}>
        <img style={{ cursor: "pointer" }} src="/imgs/ico/close-btn.svg" onClick={() => props.setIsOpen(true)} />
      </CloseSectionWrapper>
    </>
  );
}

export default CloseSection;

const CloseSectionWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  max-width: 486px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
`;
