import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/atoms/layout/mainLayout";
import CloseSection from "../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import StepBtn from "../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../components/atoms/speechBubble/speechBubble";
import MainChoice from "../../components/atoms/choice/mainChoice";
import ChoiceText20 from "../../components/atoms/text/choiceText20";
import ChoiceLayout from "../../components/atoms/layout/choiceLayout";
import ChoiceIco from "../../components/atoms/choice/choiceIco";
import BubbleTextMain from "../../components/atoms/text/bubbleTextMain";

function Flow2(props) {
  const [isChecked, setIsChecked] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("name")) {
      navigate("/ai-curation/flow/1");
      return;
    }
    let gender = localStorage.getItem("gender");
    if (gender) {
      if (gender === "WOMAN") {
        setIsChecked(1);
        return;
      }
      if (gender === "MAN") {
        setIsChecked(2);
        return;
      }
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={2} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>
              {localStorage.getItem("name")}님의 성별을 알려주세요
            </BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <ChoiceLayout style={{ height: "341px" }}>
          <MainChoice
            onClick={() => {
              setIsChecked(1);
              localStorage.setItem("gender", "WOMAN");
            }}
            style={{
              borderColor: `${isChecked === 1 ? "#FD5592" : ""}`,
              width: "100%",
            }}
          >
            <img src={`/imgs/ico/woman${isChecked === 1 ? "-ac" : ""}.svg`} />
            <ChoiceText20
              style={{ color: `${isChecked === 1 ? "#262C31" : ""}` }}
            >
              여아
            </ChoiceText20>
            {isChecked === 1 ? <ChoiceIco /> : ""}
          </MainChoice>
          <MainChoice
            onClick={() => {
              setIsChecked(2);
              localStorage.setItem("gender", "MAN");
            }}
            style={{
              borderColor: `${isChecked === 2 ? "#FD5592" : ""}`,
              width: "100%",
            }}
          >
            <img src={`/imgs/ico/man${isChecked === 2 ? "-ac" : ""}.svg`} />
            <ChoiceText20
              style={{ color: `${isChecked === 2 ? "#262C31" : ""}` }}
            >
              남아
            </ChoiceText20>
            {isChecked === 2 ? <ChoiceIco /> : ""}
          </MainChoice>
        </ChoiceLayout>
        <StepBtn nextStep={"/ai-curation/flow/3"} toastOpen={!isChecked} />
      </MainLayout>
    </>
  );
}

export default Flow2;
