import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/atoms/layout/mainLayout";
import CloseSection from "../../../components/molecules/closeSection/closeSection";
import ProgressBar from "../../../components/molecules/progressBar/progressBar";
import StepBtn from "../../../components/molecules/stepBtn/stepBtn";
import SpeechBubbleSet from "../../../components/atoms/layout/speechBubbleSet";
import SpeechBubble from "../../../components/atoms/speechBubble/speechBubble";
import BubbleTextMain from "../../../components/atoms/text/bubbleTextMain";
import OxChoice from "../../../components/molecules/oxChoice/oxChoice";

function Flow7_6(props) {
  const [isChecked, setIsChecked] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("7")) {
      navigate("/ai-curation/flow/7");
      return;
    }
  }, []);

  return (
    <>
      <MainLayout>
        <ProgressBar percent={13} />
        <SpeechBubbleSet>
          <img style={{ width: "80px" }} src="/imgs/beki/beki-flow.png" />
          <SpeechBubble style={{ margin: "2.9px 0 32px 0" }}>
            <BubbleTextMain>
              하루 총 수면 시간이 또래에 비해 짧은가요?
            </BubbleTextMain>
          </SpeechBubble>
        </SpeechBubbleSet>
        <OxChoice
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          step={"7-6"}
        />
        <StepBtn nextStep={"/ai-curation/flow/7-6-1"} toastOpen={!isChecked} />
      </MainLayout>
    </>
  );
}

export default Flow7_6;
